@import "../../main";

.breadcrumbs-wrap {
  position: absolute;
  top: 60px;
  margin: 33px 0;
  padding: 0 20px;

  .first-link {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    color: $blueLink;
  }

  .non-active-link {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: $black;
    text-decoration: none;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .icon-right {
    color: $blueLink;
    margin: 0 10px;

  }
}