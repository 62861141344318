@import "../../main.scss";

.client-details-whole-page-progress {
  position: absolute;
  top: 60px;
  bottom: 0px;
  right: 0px;
  // side bar
  left: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  background-color: #7575756e;
  z-index: 1000;
}

input[type="checkbox"]:checked + span {
  background-color: #0275b6; /* Highlighted background */
  border-color: #0275b6; /* Highlighted border */
}

input[type="checkbox"]:checked + span + .checkmark {
  display: block; /* Show the checkmark */
}

.client-details-progress {
  position: fixed;
  top: 60px;
  bottom: 0px;
  right: 0px;
  // side bar
  left: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div svg {
    color: #0275b6;
  }
}

.client-details-progress-text {
  margin-top: 16px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  text-shadow: 1px 1px #00000065;
}

.client-detail-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
  align-items: center;
  height: 71px;

  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #000000;
    margin-right: 3px;
    margin-bottom: 0px;
  }
}

.client-detail-header-locked-img {
  width: 24px;
  height: 24px;
}

.client-detail-header-unlocked-img {
  width: 24px;
  height: 24px;
}

.client-detail-header-line {
  width: 1px;
  height: 38px;
  opacity: 0.5;
  border: solid 1px #979797;
  margin-right: 31px;
  margin-left: 21px;
}

.client-detail-header-button {
  width: 200px;
  height: 40px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;

  &:active {
    opacity: 0.5;
  }
}

.client-detail-header-button-disabled {
  width: 200px;
  height: 40px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
}

.client-detail-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 11px;
  margin-right: 30px;
}

.client-detail-tabs-container {
  display: flex;
  flex-direction: row;
}

.client-detail-tab-profile {
  border: none;
  height: 44px;
  width: 146px;
  background-color: #ffffff;
  border-right: solid 1px rgba(0, 0, 0, 0.25);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 43px;

  img {
    width: 24px;
    height: 24px;
  }
}

.client-detail-tab-profile-unselected {
  border: none;
  height: 44px;
  width: 146px;
  background-color: transparent;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;

  img {
    width: 24px;
    height: 24px;
  }
}

.client-detail-tab-funds {
  border: none;
  height: 44px;
  width: 146px;
  background-color: #ffffff;
  border-right: solid 1px rgba(0, 0, 0, 0.25);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.client-detail-tab-funds-unselected {
  border: none;
  height: 44px;
  width: 146px;
  background-color: transparent;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.client-detail-profile-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 24px;
  border-radius: 2px;
  padding-left: 43px;
}

.client-detail-profile-loader {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 24px;
  border-radius: 2px;
  padding-left: 43px;

  div svg {
    color: #0275b6;
  }
}

.profile-tab-progress {
  margin-left: 4px;

  svg {
    color: #0275b6;
  }
}

.client-detail-funds-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 24px;
  border-radius: 2px;
  padding: 20px;
  min-width: 1050px;
}

.client-detail-funds-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #7575756e;
  z-index: 100;

  div svg {
    color: #0275b6;
  }
}

.client-detail-profile-document-floater {
  z-index: 999;
  position: fixed;
  margin-top: 100px;
  right: 79px;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: auto !important;
  border-radius: 2px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  max-height: 100vh; 
  overflow: auto;
  padding-bottom: 30px;
}

.document-circular-loader {
  position: absolute;
  background-color: #7d7d7d8c;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
}

.document-status-progress {
  svg {
    color: #0275b6;
  }
}

.document-status-text-progress {
  margin-top: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #ffffff;
  text-shadow: 1px 1px #00000065;
}

.client-detail-profile-document-header {
  margin-top: 24px;
  margin-left: 28px;
  margin-bottom: 16px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.client-detail-profile-document-container {
  display: flex;
  flex-direction: column;

  margin-left: 30px;
  margin-right: 30px;
}

.client-detail-profile-document-magnifier {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  overflow-y: auto;
  height: 280px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  &:hover {
    cursor: pointer;
  }
}

.client-detail-document-image {
  width: 400px;
  height: 259px;
}

.client-detail-document-image-zoomed {
  width: 800px;
  height: 518px;
}

.client-detail-document-zoom-button {
  position: absolute;
  top: 292px;
  right: 42px;
  width: 32px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
}

.client-detail-document-rotate-left-button {
  position: absolute;
  top: 292px;
  right: 126px;
  width: 32px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
}

.client-detail-document-rotate-right-button {
  position: absolute;
  top: 292px;
  right: 84px;
  width: 32px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
}

.client-detail-profile-document-switcher {
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.client-detail-documents-mini {
  width: 66px;
  height: 42px;
  border: solid 1px #0275b6;
  margin-right: 8px;
  margin-left: 8px;
  background: #cfcfcf;

  &:hover {
    cursor: pointer;
  }
}

.client-detail-documents-mini-unselected {
  width: 66px;
  height: 42px;
  border: solid 1px #f1f1f1;
  margin-right: 8px;
  margin-left: 8px;
  background: #cfcfcf;
  opacity: 0.5;

  &:hover {
    cursor: pointer;
  }
}

.client-detail-image-switcher-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.client-detail-documents-left-arrow {
  margin-left: 16px;
  margin-right: 8px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
}

.client-detail-documents-left-arrow-disabled {
  margin-left: 16px;
  margin-right: 8px;
  opacity: 0.5;
}

.client-detail-documents-right-arrow {
  margin-right: 16px;
  margin-left: 8px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
}

.client-detail-documents-right-arrow-disabled {
  margin-left: 16px;
  margin-right: 8px;
  opacity: 0.5;
}

.client-detail-profile-document-details {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
}

.client-detail-document-pending {
  min-width: 90px;
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #e5af09;
}

.client-detail-document-approved {
  min-width: 90px;
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #13ce95;
}

.client-detail-document-rejected {
  min-width: 90px;
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #d56060;
}

.client-detail-profile-document-details-headers {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: flex-end;
  padding-bottom: 8px;
}

.client-detail-profile-document-details-headers-doctype {
  margin-bottom: 0px;
  margin-right: 98px;
  margin-left: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.client-detail-profile-document-details-headers-filename {
  margin-bottom: 0px;
  margin-right: 89px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.client-detail-profile-document-details-headers-date {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.client-detail-profile-document-details-values {
  display: flex;
  height: 78px;
  background-color: #fbfbfb;
}

.client-detail-profile-document-details-values-doctype {
  width: 140px;
  margin-left: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #484848;
}

.client-detail-profile-document-details-values-filename {
  width: 140px;
  margin-left: 30px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #484848;
}

.client-detail-profile-document-details-values-date {
  margin-left: 25px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #484848;
}

.client-detail-documents-left-arrow {
  width: 32px;
  height: 32px;
  background-color: transparent;
}

.client-detail-documents-right-arrow {
  width: 32px;
  height: 32px;
  background-color: transparent;
}

.client-detail-profile-document-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 30px;
  margin-right: 30px;
}

.space-between {
  min-width: 24px;
}

.client-detail-profile-document-undo {
  min-width: 24px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
}

.client-detail-profile-document-undo-disabled {
  min-width: 24px;
  height: 24px;
  opacity: 0.5;
}

.client-detail-profile-document-reject {
  margin-left: 16px;
  min-width: 132px;
  height: 44px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
}

.client-detail-profile-document-reject-disabled {
  margin-left: 16px;
  min-width: 132px;
  height: 44px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  opacity: 0.5;
}

.client-detail-profile-document-refresh {
  margin-left: 155px;
  margin-top: -35px;
  width: 63%;
  height: 44px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

.client-detail-profile-document-approve {
  margin-left: 16px;
  min-width: 128px;
  height: 44px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

.client-detail-profile-document-approve-disabled {
  margin-left: 16px;
  min-width: 128px;
  height: 44px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
}

.client-detail-profile-header {
  display: flex;
  flex-direction: row;
  margin-top: 43px;
}

.client-detail-profile-account-status {
  display: flex;
  flex-direction: row;

  img {
    margin-top: -3px;
    width: 24px;
    height: 24px;
  }
}

.client-detail-profile-account-status-text {
  margin-right: 84px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.client-detail-profile-account-status-pending {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #e5af09;
}

.client-detail-profile-account-status-approved {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #13ce95;
}

.client-detail-profile-account-status-rejected {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #d56060;
}

.client-detail-profile-actions-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 49px;
}

.client-detail-profile-approve-client {
  width: 200px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: transparent;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;

  &:active {
    opacity: 0.5;
  }
}

.client-detail-profile-approve-client-disabled {
  width: 200px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: transparent;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  opacity: 0.5;
}

.client-detail-profile-reject-client {
  margin-left: 24px;
  width: 200px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: transparent;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;

  &:active {
    opacity: 0.5;
  }
}

.client-detail-profile-reject-client-disabled {
  margin-left: 24px;
  width: 200px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: transparent;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  opacity: 0.5;
}
.client-detail-profile-status-icon {
  width: 24px;
  height: 24px;
}

.client-detail-profile-account-photo-container {
  display: flex;
  flex-direction: row;

  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    margin-right: 100px;
    color: #000000;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 56px;

    p {
      margin-right: 0;
      margin-top: 20px;
    }

    img {
      width: 110px;
      height: 110px;
      border-radius: 55px;
      background: #ededed;
    }
  }
}

.client-detail-profile-account-unitholderid-container {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.client-detail-profile-account-unitholderid-text {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.client-detail-profile-account-unitholderid-value {
  margin-left: 92px;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #000000;
}

.upload-doc-input-container-unitholder {
  width: 420px;
  height: 40px;
  margin-left: 21px;
  margin-right: 24px;
  margin-bottom: 24px;
}
.upload-doc-upload-icon-unitholder {
  width: 60px;
  height: 38px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #0275b6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.upload-doc-input-unitholder {
  max-width: 420px;
  height: 40px;
  border-radius: 12px;
  border: solid 1px #dddddd;
  margin-right: 24px;
  margin-bottom: 24px;
  text-overflow: ellipsis;
}

.upload-doc-error-text {
  color: red;
  font-size: 12px;
  margin-top: -20px;
}