.funddetails-progress {
	position: absolute;
    // header
    top: 60px;
    bottom: 0;
    // side bar
    left: 200px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #7575756e;
    z-index: 100;
    div svg {
        color: #0275b6;
    }
}

.funddetails-progress-text {
	margin-top: 16px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    text-shadow: 1px 1px #00000065;
}

.fund-detail-header {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding-right: 23px;
	padding-top: 23px;
	background-color: #ffffff;
	border-top: solid 1px #dddddd;
}

.fundrec-detail-cancel {
	width: 131px;
	height: 40px;
	border-radius: 3px;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	text-align: center;
	color: #0275b6;
	margin-right: 14px;
	background-color: transparent;
}

.fund-detail-create {
	width: 131px;
	height: 40px;
	border-radius: 3px;
	background-color: #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	text-align: center;
	color: #ffffff;
	border: none;
	&:disabled {
		opacity: 0.5;
	}
}

.fund-detail-clone {
	width: 177px;
	height: 40px;
	border-radius: 3px;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	text-align: center;
	color: #0275b6;
	margin-right: 24px;
	background-color: transparent;
}

.fund-detail-tab {
	padding-top: 44px;
	padding-left: 21px;
	background-color: #ffffff;
}

.fund-detail-tabbutton {
	font-family: Lato;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: 0.2px;
	text-align: center;
	color: #737373;
	background-color: #ffffff;
	padding-left: 26px;
	padding-right: 26px;
	border: none;
}

.fund-detail-tabbutton-selected {
	font-family: Lato;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: 0.2px;
	text-align: center;
	color: #000000;
	background-color: #ffffff;
	padding-left: 26px;
	padding-right: 26px;
	border: none;
	border-bottom: solid 3px #004d78;
}