
.regisDevice-search-header {
	display: flex;
    min-height: 71px;
	margin-left: 20px;
	margin-bottom: 8px;
    flex-direction: row;
    justify-content: flex-start;
}

.regisDevice-search-container {
    display: flex;
    flex-direction: column;
}

.regisDevice-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 23px;
    width: 331px;
    height: 40px;
    padding-left: 16px;
    border: solid 1px #cfcfcf;
    background-color: #ffffff;
    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }
}

.regisDevice-search-bar {
    display: flex;
    flex-grow: 1;
    border: none;
    background-color: transparent;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #aaaaaa;
    &:focus {
        outline: none;
    }
}

.regisDevice-list-progress {
    margin-left: 30px;
    position: absolute;
    // header
    top: 60px;
    bottom: 0;
    // side bar
    left: 200px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #7575756e;
    z-index: 100;
    div svg { color: #0275b6; }
}

.regisDevice-list-progress-text {
    margin-top: 16px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    text-shadow: 1px 1px #00000065;
}

.regisDevice-search-header {
	display: flex;
    min-height: 71px;
	margin-left: 20px;
	margin-bottom: 8px;
    flex-direction: row;
    justify-content: flex-start;
}

.regisDevice-search-container {
    display: flex;
    flex-direction: column;
}

.regisDevice-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 23px;
    width: 331px;
    height: 40px;
    padding-left: 16px;
    border: solid 1px #cfcfcf;
    background-color: #ffffff;
    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }
}

.regisDevice-search-bar {
    display: flex;
    flex-grow: 1;
    border: none;
    background-color: transparent;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #aaaaaa;
    &:focus {
        outline: none;
    }
}

.regisDevice-list-begin-search {
    &:hover {
        cursor: pointer;
    }
    &:active {
        opacity: 0.5;
    } 
}

.regisDevice-list-cancel-search {
    &:hover {
        cursor: pointer;
    }
    &:active {
        opacity: 0.5;
    }
}

.regisDevice-list-container {
    min-width: 600px;
    min-height: 600px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 22px;
    overflow-x: auto;
	padding: 20px;
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    border-left: solid 1px rgba(0, 0, 0, 0.05);
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
	&::-webkit-scrollbar-track {
        background: #9797977c;
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
        height: 8px;
        background: #9797977c;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background:  #0275b6;
        border-radius: 4px;
        height: 8px;
    }
}

.regisDevice-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.regisDevice-head-container {
    background-color: #ffffff;
    border-radius: 2px;
    border-bottom: solid 1px;
    border-bottom-color: rgba(0, 0, 0, 0.1);
}

.regisDevice-head-row {
    background-color: #ffffff;
}

.regisDevice-header {
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #000000;
    height: 62px;
    padding-left: 20px;
}


.regisDevice-table-floating-header {
    position: sticky;
    right: 0px;
    width: 132px;
    background-color: #ffffff;
}


.regisDevice-header-with-sort {
	display: flex;
    flex-direction: row;
    background-color: transparent;
    border: none;
    text-align: left;
    justify-content: left;
     align-items: center;
    padding-left: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #000000;
    height: 62px;

    img {
        min-width: 16px;
        max-width: 16px;
        min-height: 16px;
        max-height: 16px;
    }
}

// fund no column
.regisDevice-header:nth-child(1) {
    width: 110px;
}

// fund code column
.regisDevice-header:nth-child(2) {
    width: 281px;
}

// asset class column
.regisDevice-header:nth-child(3) {
    width: 139px;
}

// risk level column
.regisDevice-header:nth-child(4) {
    width: 116px;
}

// nav thb column
.regisDevice-header:nth-child(5) {
    width: 112px;
}

// nav date column
.regisDevice-header:nth-child(6) {
    width: 110px;
}

// fund status column
.regisDevice-header:nth-child(7) {
    width: 130px;
}

// action column
.regisDevice-header:nth-child(8) {
    width: 132px;
}

.regisDevice-table-body {

}

.regisDevice-table-empty-row {
    background-color: #ffffff;
    min-height: 660px;
    height: 660px;
}

.regisDevice-table-no-data {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 635px;
    margin-left: 21px;
    margin-right: 20px;
    margin-bottom: 50px;
    background-color: #ffffff;
}
.regisDevice-textOverflow {
    word-wrap: break-word;
}
.labelInputText {
    width: 242px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.regisDevice-table-no-data-header {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.regisDevice-table-no-data-body {
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.regisDevice-table-row {
    border-bottom: solid 0.5px #dddddd;
}

.regisDevice-table-row:nth-child(odd) {
    background-color: #fbfbfb;
}

.regisDevice-table-row:nth-child(even) {
    background-color: #ffffff;
}

.regisDevice-cell {
    height: 66px;
	padding-left: 20px;
	
}

.regisDevice-status-active {
	width: 66px;
  height: 17px;
  border-radius: 8.5px;
  background-color: #13ce95;
  p {
	  text-align: center;
	  font-family: Lato;
	  font-size: 14px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.29;
	  letter-spacing: 0.2px;
	  color: #ffffff;
  }
}

.regisDevice-status-inactive {
	width: 66px;
  height: 17px;
  border-radius: 8.5px;
  background-color: #d56060;
  p {
	  text-align: center;
	  font-family: Lato;
	  font-size: 14px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.29;
	  letter-spacing: 0.2px;
	  color: #ffffff;
  }
}

.regisDevice-action-buttons {
	display: flex;
	flex-direction: row;
}

.regisDevice-action-view {
	border: none;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: underline;
	font-family: Lato;
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: 0.18px;
	color: #0275b6;
	padding-left: 0px;
	border-right: solid 1px #d6d6d6;
	img {
		width: 16px;
		height: 16px;
	}
}

.regisDevice-action-edit {
	border: none;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: underline;
	font-family: Lato;
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: 0.18px;
	color: #0275b6;
	img {
		width: 16px;
		height: 16px;
	}
}

.regisDevice-buttons-container {
	margin-top: 23px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.regisDevice-upload-doc-button {
	width: 243px;
	height: 40px;
	border-radius: 4px;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	color: #0275b6;
	margin-right: 14px;
	img {
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}
}

.regisDevice-create-fund-button {
	width: 243px;
	height: 40px;
	border-radius: 4px;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	color: #0275b6;
}

