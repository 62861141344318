.error-fd-modal {
	position: fixed;
	// header
    top: 0px;
    bottom: 0;
	// side bar
    left: 200px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #7575756e;
    z-index: 100;
}

.error-fd-modal-content {

	min-width: 526px;
	min-height: 289px;
	display: flex;
	flex-direction: column;
	border-radius: 3px;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px #e2e1e1;
	background-color: #ffffff;

	img {
		width: 16px;
		height: 16px;
		padding-top: 18px;
		padding-right: 18px;
		align-self: flex-end;
	}

	h3 {
		margin-top: 35px;
		font-family: Lato;
		font-size: 18px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: 0.2px;
		text-align: center;
		color: #000000;
	}
}

.error-fd-modal-message {
	margin-top: 9px;
	font-family: Lato;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.2px;
	text-align: center;
	color: #000000;
}

.error-fd-modal-button {
	margin-top: 46px;
	margin-bottom: 60px;
	width: 148px;
	height: 44px;
	border-radius: 3px;
	background-color: #0275b6;
	border: none;
	align-self: center;

	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	text-align: center;
	color: #ffffff;
}