.basic-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  transition: all 0.3s ease;

  &:active {
    opacity: 0.5;
  }

  &.basic-button-disabled {
    cursor: not-allowed;
  }
}
