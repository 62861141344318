.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  min-height: 40px;
  height: 40px;
}

.pagination-first {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 60px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: #ffffff;
  &:active {
    opacity: 0.5;
  }
}

.pagination-last {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 10px;
  min-width: 60px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: #ffffff;
  &:active {
    opacity: 0.5;
  }
}

.pagination-previous {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 10px;
  min-width: 40px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: #ffffff;
  &:active {
    opacity: 0.5;
  }
}

.pagination-previous-disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 10px;
  min-width: 40px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: #ffffff;
  opacity: 0.5;
}

.pagination-next {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 10px;
  min-width: 40px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: #ffffff;
  &:active {
    opacity: 0.5;
  }
}

.pagination-next-disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 10px;
  min-width: 40px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: #ffffff;
  &:active {
    opacity: 0.5;
  }
  opacity: 0.5;
}

.pagination-button {
  margin-left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: #ffffff;
  &:active {
    opacity: 0.5;
  }
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
}

.pagination-button-selected {
  margin-left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background-color: #0275b6;
  &:active {
    opacity: 0.5;
  }
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

/* styles.scss */
/* Table Styles */
.table-container {
  max-width: 100%;
  margin: 20px auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling (let tbody handle it) */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.table {
  width: 100%;
  table-layout: auto; /* Allow table to resize dynamically based on content */
  border-collapse: collapse;
}

.table thead th,
.table tbody td {
  text-align: left;
  padding: 10px 15px;
  font-weight: bold;
  color: #333;
  white-space: normal; /* Allow text wrapping */
  word-wrap: break-word; /* Break words when content exceeds max width */
  word-break: break-word; /* Ensure word-breaking in all browsers */
  max-width: 200px; /* Set a reasonable maximum width for columns */
  overflow: hidden; /* Prevent content from overflowing */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

.table tbody tr td {
  padding: 10px 15px;
  text-align: left;
  vertical-align: top;
}

.table-list-container {
  max-width: 100%;
  overflow-x: auto; /* Allow horizontal scrolling */
  min-height: 80vh; /* Ensure the container has a minimum height */
  padding: 20px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 4px;
}

/* Scrollbar styles */
.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
  background: #0275b6;
  border-radius: 4px;
}

.table-row {
  display: table-row; /* Treat as normal row */
  height: 9rem;
  width: 100%;
}

.table-cell {
  padding: 8px 12px;
  white-space: normal; /* Allow wrapping */
  overflow-wrap: break-word;
  word-break: break-word;
  text-overflow: ellipsis; /* Add ellipsis for long content */
}

/* Add responsive behavior */
@media (max-width: 768px) {
  .table thead th,
  .table tbody td {
    max-width: 150px; /* Adjust max width for smaller screens */
  }
}
