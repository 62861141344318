@import "../../main.scss";

.wrapComponent{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
}

.tableWrap {
    display: flex;
    flex-direction: column;
    // the 200px is the width of the sidebar on the left.
    width: calc(100vw - 200px); 
}

.tableContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #ededed;
}