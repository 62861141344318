@import "../../main.scss";

.client-list-tool-bar-container {
  margin: 0 12px 10px 15px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .form-control {
    // temporary
    margin-bottom: 0 !important;
  }
}

.clients-list-progress {
  margin-left: 30px;
  position: absolute;
  // header
  top: 60px;
  bottom: 0;
  // side bar
  left: 200px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #7575756e;
  z-index: 100;

  div svg {
    color: #0275b6;
  }
}

.clients-list-progress-text {
  margin-top: 16px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  text-shadow: 1px 1px #00000065;
}

.clients-container {
  display: flex;
  flex-direction: column;
  margin-left: 21px;
  margin-right: 70px;
  margin-bottom: 40px;
  overflow-y: hidden;
  overflow-x: scroll;
  border-radius: 4px;
  border-right: solid 1px rgba(0, 0, 0, 0.1);
  border-left: solid 1px rgba(0, 0, 0, 0.05);
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar-track {
    background: #9797977c;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 8px;
    background: #9797977c;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #0275b6;
    border-radius: 4px;
    height: 8px;
  }
}

.clients-search-header {
  display: flex;
  min-height: 125px;
  margin-right: 70px;
  flex-direction: row;
  justify-content: flex-end;
}

.clients-search-container {
  display: flex;
  flex-direction: column;
}

.clients-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 23px;
  width: 331px;
  height: 40px;
  padding-left: 16px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;

  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
}

.clients-search-bar {
  display: flex;
  flex-grow: 1;
  border: none;
  background-color: transparent;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #aaaaaa;

  &:focus {
    outline: none;
  }
}

.clients-details-begin-search {
  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
}

.client-details-cancel-search {
  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
}

.clients-radio-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;

  input {
    margin-top: 0px;
    margin-right: 6px;
  }

  label {
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #737373;
    text-align: center;
    margin-bottom: 0px;
    margin-right: 26px;
  }
}

.clients-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.clients-table-no-data {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 635px;
  margin-left: 21px;
  margin-right: 20px;
  margin-bottom: 50px;
  background-color: #ffffff;
}

.clients-table-no-data-header {
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #000000;
}

.clients-table-no-data-body {
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.2px;
  text-align: center;
  color: #000000;
}

.clients-table-body {
}

.clients-table-empty-row {
  background-color: #ffffff;
  min-height: 660px;
  height: 660px;
}

.clients-table-row {
  border-bottom: solid 1px;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.clients-cell {
  height: 66px;
  padding-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.clients-table-floating-cell {
  position: sticky;
  position: -webkit-sticky;
  right: 0px;
  border-bottom: solid 1px;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  width: 85px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;

    &:hover {
      opacity: 0.5;
    }

    &:active {
      opacity: 1;
    }
  }

  p {
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.18px;
    color: #0275b6;
    text-decoration: underline;
  }
}

.clients-table-cell-account-status {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 25px;
    height: 24px;
  }
}

.clients-table-cell-account-status-pending {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #e5af09;
}

.clients-table-cell-account-status-approved {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #13ce95;
}

.clients-table-cell-account-status-rejected {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #d56060;
}

.clients-table-cell-document-status {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 25px;
    height: 24px;
  }
}

.clients-table-cell-document-status-approved {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #13ce95;
}

.clients-table-cell-document-status-rejected {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #d56060;
}

.clients-table-cell-document-status-pending {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #e5af09;
}

.clients-table-cell-document-status-waiting {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #e5af09;
}

.clients-table-cell-document-status-in-review {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #e5af09;
}

.clients-table-header-submission {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: none;
  text-align: left;
  justify-content: left;
  align-items: flex-start;
  padding-left: 0px;
}

.clients-table-cell-action {
  text-align: left;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #0275b6;
  text-decoration: underline;
}

.clients-table-row:nth-child(odd) {
  background-color: #ededed;
}

.clients-table-row:nth-child(even) {
  background-color: #ffffff;
}

.clients-header-sortby {
  position: relative;
  display: inline-block;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  width: 117px;
  height: 62px;

  &:hover {
    .clients-header-sortby-radio-container {
      display: block;
      margin-left: 20px;
    }
  }
}

.clients-header-sortby-text-container {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90px;
  height: 62px;
  text-align: left;
  padding-left: 5px;
  margin-right: 17px;
  background-color: #ffffff;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;

  img {
    margin-top: 5px;
    align-self: flex-start;
  }

  p {
    width: 50px;
    margin-bottom: 0px;
  }

  &:hover {
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.15);

    img {
      transform: rotate(180deg);
    }
  }
}

.clients-header-sortby-radio-container {
  display: none;
  position: absolute;
  z-index: 3;
  left: -20px;
  height: 189px;
  width: 177px;
  background-color: #ffffff;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 16px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  p {
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #858585;
    margin-bottom: 17px;
  }

  input[type="checkbox"] {
    display: none;
    width: 16px;
  }

  input[type="checkbox"] {
    background-image: require("../../assets/images/empty.svg");
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
    margin-right: 10px;
  }

  input[type="checkbox"]:checked {
    background: #0080ff;
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
  }

  label {
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #484848;
    margin-bottom: 0px;
  }
}

.clients-header-sortby-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}

.clients-header-sortby-accStatus {
  position: relative;
  display: inline-block;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 117px;
  height: 62px;

  &:hover {
    .clients-header-sortby-radio-container-accStatus {
      display: block;
      margin-left: 20px;
    }
  }
}

.clients-header-sortby-text-container-accStatus {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90px;
  height: 62px;
  text-align: left;
  padding-left: 5px;
  margin-right: 17px;
  background-color: #ffffff;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;

  img {
    margin-top: 5px;
    align-self: flex-start;
  }

  p {
    width: 50px;
    margin-bottom: 0px;
  }

  &:hover {
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.15);

    img {
      transform: rotate(180deg);
    }
  }
}

.clients-header-sortby-radio-container-accStatus {
  display: none;
  position: absolute;
  z-index: 1;
  left: -20px;
  height: 189px;
  width: 177px;
  background-color: #ffffff;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 16px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  p {
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #858585;
    margin-bottom: 17px;
  }

  input[type="checkbox"] {
    display: none;
    width: 16px;
  }

  input[type="checkbox"] {
    background-image: require("../../assets/images/empty.svg");
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
    margin-right: 10px;
  }

  input[type="checkbox"]:checked {
    background: #0080ff;
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
  }

  label {
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #484848;
    margin-bottom: 0px;
  }
}

.clients-header-sortby-converted-user {
  position: relative;
  display: inline-block;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 150px;
  height: 62px;

  &:hover {
    .clients-header-sortby-radio-container-converted-user {
      display: block;
      margin-left: 20px;
    }
  }
}

.clients-header-sortby-text-container-converted-user {
  position: relative;
  z-index: 7;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 120px;
  height: 62px;
  text-align: left;
  padding-left: 5px;
  background-color: #ffffff;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;

  img {
    margin-top: 5px;
    align-self: flex-start;
  }

  p {
    width: 100px;
    margin-bottom: 0px;
  }

  &:hover {
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.15);

    img {
      transform: rotate(180deg);
    }
  }
}

.clients-header-sortby-radio-container-converted-user {
  display: none;
  position: absolute;
  z-index: 6;
  left: -20px;
  height: 120px;
  width: 160px;
  background-color: #ffffff;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 16px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  p {
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #858585;
    margin-bottom: 17px;
  }

  input[type="checkbox"] {
    display: none;
    width: 16px;
  }

  input[type="checkbox"] {
    background-image: require("../../assets/images/empty.svg");
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
    margin-right: 10px;
  }

  input[type="checkbox"]:checked {
    background: #0080ff;
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
  }

  label {
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #484848;
    margin-bottom: 0px;
  }
}

.clients-head-container {
  background-color: #ffffff;
  border-radius: 2px;
  border-bottom: solid 1px;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.clients-head-row {
  background-color: #ffffff;
}

.clients-table-floating-header {
  position: sticky;
  right: 0px;
  width: 85px;
  background-color: #ffffff;
}

.clients-header {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.2px;
  color: #000000;
  height: 62px;
  padding-left: 20px;
}

// Number column
.clients-header:nth-child(1) {
  width: 64px;
}

// First name english column
.clients-header:nth-child(2) {
  width: 153px;
}

// Last name english column
.clients-header:nth-child(3) {
  width: 151px;
}

// first name thai column
.clients-header:nth-child(4) {
  width: 158px;
}

// last name thai column
.clients-header:nth-child(5) {
  width: 169px;
}

// contact no column
.clients-header:nth-child(6) {
  width: 173px;
}

// submission date column
.clients-header:nth-child(7) {
  width: 143px;
}

// enhanced kyc column
.clients-header:nth-child(8) {
  width: 110px;
}

// last review date column
.clients-header:nth-child(9) {
  width: 134px;
}

// risk level column
.clients-header:nth-child(10) {
  width: 77px;
}

// doc status column
.clients-header:nth-child(11) {
  width: 127px;
}

// acc status column
.clients-header:nth-child(12) {
  width: 131px;
}

// action status column
.clients-header:nth-child(13) {
  width: 109px;
}

.applicants-table-cell-account-status-unlocked {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.applicants-table-cell-account-status-locked {
  margin-bottom: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #d56060;
}
