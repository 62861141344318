@import "../../../main.scss";
.sectionTitle{
  margin-top: 24px;
  margin-bottom: 24px;
}
.sectionTitleText{
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}
.fieldContainer{
  width: 568px;
  height: 40px;
  margin-bottom: 20px;
}
.customerFieldContainer{
  width: 568px;
  height: 40px;
  margin-bottom: 180px;
}
.field{
  display: flex;
  flex-direction: row;
}
.fieldLabel{
  width: 200px;
  display: flex;
  align-self: center;
  padding-left: 12px;
  padding-right: 12px;
}
.fieldValue{
  width: 360px;
  height: 40px;
  padding-left: 12px;
  border: solid 1px #cfcfcf;
  background-color: #f7f7f7;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #000000;
}
.fieldValueDisabled{
  width: 360px;
  height: 40px;
  padding-left: 12px;
  border: solid 1px #cfcfcf;
  background-color: $inactivePlaceholder;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #337ab7
}
.fieldValueRequire{
  width: 360px;
  height: 40px;
  padding-left: 12px;
  border: solid 1px #ff0000;
  background-color: $inactivePlaceholder;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #aaaaaa;
}
.dropDown{
  width: 360px;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
}
.dropDownDisabled{
	font-family: Lato;
  color: #aaaaaa;
  svg {
    fill: #9d9d9d;
  }
  >div {
    min-height: 40px;
    border-radius: 0;
	background-color: $inactivePlaceholder;
    border: 1px solid $borderInputGrey;
    color: $inactiveValue;
    div:first-child {
      color: #337ab7;
    }
    input[disabled] {
      color: $inactiveValue!important;
    }
  }
  span {
    display: none;
  }
}

.dropDownRequire{
	font-family: Lato;
  color: #aaaaaa;
  svg {
    fill: #9d9d9d;
  }
  >div {
    min-height: 40px;
    border-radius: 0;
	background-color: $inactivePlaceholder;
    border: 1px solid #ff0000;
    color: $inactiveValue;
    div:first-child {
    }
    input[disabled] {
      color: $inactiveValue!important;
    }
  }
  span {
    display: none;
  }
}

.calendarWrap {
	position: relative;
	width: 360px;
	img  {
	  position: absolute;
	  top: 50%;
	  left: 10px;
	  transform: translateY(-50%);
	  z-index: 1;
	}
	.react-datepicker-wrapper {
	  display: block;
	  .react-datepicker__input-container {
		display: block;
		input {
		  width: 100%;
		  min-height: 40px;
		  padding-left: 40px;
		  background-image: url('../../../assets/images/calendar/calendar.svg');
		  background-repeat: no-repeat;
		  border: 1px solid $borderInputGrey;
		  background-position: 11px;
  
		}
		input::placeholder {
		  font-size: 16px;
		}
		input[disabled] {
		  background-color: $inactivePlaceholder;
		  border: 1px solid $borderInputGrey;
      color: #337ab7;
		}
	  }
	}
  }
  .radioButtonsWrap{
    width: 360px;
    padding-left: 23px;
    div{
      display: inline-block;
    display: flex;
    flex-direction: row !important;
      label{
        span {
          font-family: Lato;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: 0.2px;
        }
      }
    }
  }

  .customRadioButtonsWrap{
    width: 500px;
    padding-left: 23px;
    div{
      display: inline-block;
      flex-wrap: nowrap !important;
      display: flex;
      flex-direction: row !important;
      label{
        span {
          font-family: Lato;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: 0.2px;
        }
      }
    }
  }

  .mailingAddressButtonsWrap{
    width: 500px;
    padding-left: 23px;
    div{
      display: inline-block;
      flex-wrap: nowrap !important;
      display: flex;
      label{
        span {
          font-family: Lato;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: 0.2px;
        }
      }
    }
  }
  
  .space{
    height: 20px;
  }
  