.funddetailsextra-container {
	margin-top: 24px;
	margin-left: 21px;
	margin-right: 20px;
	margin-bottom: 24px;
}

.funddetailsextra-hide {
	display: none;
}

.funddetailsextra-tab-container {
	display: flex;
	flex-direction: row;
	min-height: 34px;
}

.funddetailsextra-english-container {
	background-color: white;
	padding-left: 61px;
	padding-right: 33px;
	padding-top: 40px;
	padding-bottom: 50px;
}

.funddetailsextra-english-container-hide {
	display: none;
}

.funddetailsextra-spacer {
	min-height: 24px;
}

.funddetailsextra-tab-selected {
	min-width: 129px;
	background-color: white;
	font-family: Lato;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: 0.2px;
	color: #000000;
	border-top-right-radius: 3px;
	border: none;
}

.funddetailsextra-tab {
	min-width: 129px;
	background-color: transparent;
	font-family: Lato;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: 0.2px;
	color: #000000;
	border-top-right-radius: 3px;
	border: none;
}

.hide-tab-form {
	display: none;
}

.funddetails-preview-input {
	min-width: 476px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.preview-button {
	min-width: 122px;
	height: 40px;
	background-color: #0275b6;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.2px;
	text-align: center;
	color: #ffffff;
	&:disabled {
		opacity: 0.5;
	}
}