
.untHld-search-header {
	display: flex;
    min-height: 71px;
	margin-left: 20px;
	margin-bottom: 8px;
    flex-direction: row;
    justify-content: flex-start;
}

.untHld-search-container {
    display: flex;
    flex-direction: column;
}

.untHld-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 331px;
    height: 40px;
    padding-left: 16px;
    border: solid 1px #cfcfcf;
    background-color: #ffffff;
    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }
}
.untHld-search-disable{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 331px;
    height: 40px;
    padding-left: 16px;
    border: solid 1px #cfcfcf;
    background-color: #d7d7d7;
    pointer-events: none;
    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }
}

.untHld-search-bar {
    display: flex;
    flex-grow: 1;
    border: none;
    background-color: transparent;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #aaaaaa;
    &:focus {
        outline: none;
    }
}

.untHld-list-progress {
    position: absolute;
    // header
    top: 60px;
    bottom: 0;
    // side bar
    left: 200px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #7575756e;
    z-index: 100;
    div svg { color: #0275b6; }
}

.untHld-list-progress-text {
    margin-top: 16px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    text-shadow: 1px 1px #00000065;
}

.untHld-search-header {
	display: flex;
    min-height: 71px;
	margin-left: 20px;
	margin-bottom: 8px;
    flex-direction: row;
    justify-content: flex-start;
}

.untHld-search-container {
    display: flex;
    flex-direction: column;
}

.untHld-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 23px;
    width: 331px;
    height: 40px;
    padding-left: 16px;
    border: solid 1px #cfcfcf;
    background-color: #ffffff;
    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }
}

.untHld-search-bar {
    display: flex;
    flex-grow: 1;
    border: none;
    background-color: transparent;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #aaaaaa;
    &:focus {
        outline: none;
    }
}

.untHld-list-begin-search {
    &:hover {
        cursor: pointer;
    }
    &:active {
        opacity: 0.5;
    } 
}

.untHld-list-cancel-search {
    &:hover {
        cursor: pointer;
    }
    &:active {
        opacity: 0.5;
    }
}

.untHld-list-container {
    min-width: 600px;
    min-height: 600px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-left: 20px;
    margin-right: 70px;
    margin-bottom: 22px;
    overflow-x: auto;
	padding: 20px;
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    border-left: solid 1px rgba(0, 0, 0, 0.05);
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
	&::-webkit-scrollbar-track {
        background: #9797977c;
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
        height: 8px;
        background: #9797977c;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background:  #0275b6;
        border-radius: 4px;
        height: 8px;
    }
}

.untHld-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.untHld-head-container {
    background-color: #ffffff;
    border-radius: 2px;
    border-bottom: solid 1px;
    border-bottom-color: rgba(0, 0, 0, 0.1);
}

.untHld-head-row {
    background-color: #ffffff;
}

.untHld-header {
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #000000;
    height: 62px;
    padding-left: 20px;
}


.untHld-table-floating-header {
    position: sticky;
    right: 0px;
    width: 132px;
}


.untHld-header-with-sort {
	display: flex;
	flex-direction: row;
	background-color: transparent;
	border: none;
	text-align: left;
	justify-content: left;
	align-items: left;
	padding-left: 0px;
	font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #000000;
    height: 62px;
	img {
		min-width: 16px;
		max-width: 16px;
		min-height: 16px;
		max-height: 16px;
	}
}

// fund no column
.untHld-header:nth-child(1) {
    width: 110px;
}

// fund code column
.untHld-header:nth-child(2) {
    width: 281px;
}

// asset class column
.untHld-header:nth-child(3) {
    width: 139px;
}

// risk level column
.untHld-header:nth-child(4) {
    width: 116px;
}

// nav thb column
.untHld-header:nth-child(5) {
    width: 112px;
}

// nav date column
.untHld-header:nth-child(6) {
    width: 110px;
}

// fund status column
.untHld-header:nth-child(7) {
    width: 130px;
}

// action column
.untHld-header:nth-child(8) {
    width: 132px;
}

.untHld-table-body {

}

.untHld-table-empty-row {
    background-color: #ffffff;
    min-height: 660px;
    height: 660px;
}

.untHld-table-no-data {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 635px;
    margin-left: 21px;
    margin-right: 20px;
    margin-bottom: 50px;
    background-color: #ffffff;
}


.untHld-table-no-data-header {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.untHld-table-no-data-body {
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.untHld-table-row {
    border-bottom: solid 0.5px #dddddd;
}

.untHld-table-row:nth-child(odd) {
    background-color: #fbfbfb;
}

.untHld-table-row:nth-child(even) {
    background-color: #ffffff;
}

.untHld-cell {
    height: 66px;
	padding-left: 20px;
	word-wrap: break-word;
}

.untHld-status-active {
	width: 66px;
  height: 17px;
  border-radius: 8.5px;
  background-color: #13ce95;
  p {
	  text-align: center;
	  font-family: Lato;
	  font-size: 14px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.29;
	  letter-spacing: 0.2px;
	  color: #ffffff;
  }
}

.untHld-status-inactive {
	width: 66px;
  height: 17px;
  border-radius: 8.5px;
  background-color: #d56060;
  p {
	  text-align: center;
	  font-family: Lato;
	  font-size: 14px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.29;
	  letter-spacing: 0.2px;
	  color: #ffffff;
  }
}

.untHld-action-buttons {
	display: flex;
	flex-direction: row;
}

.untHld-action-view {
	border: none;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: underline;
	font-family: Lato;
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: 0.18px;
	color: #0275b6;
	padding-left: 0px;
	border-right: solid 1px #d6d6d6;
	img {
		width: 16px;
		height: 16px;
	}
}

.untHld-action-edit {
	border: none;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: underline;
	font-family: Lato;
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: 0.18px;
	color: #0275b6;
	img {
		width: 16px;
		height: 16px;
	}
}

.untHld-buttons-container {
	margin-top: 23px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.untHld-upload-doc-button {
	width: 243px;
	height: 40px;
	border-radius: 4px;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	color: #0275b6;
	margin-right: 14px;
	img {
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}
}

.untHld-create-fund-button {
	width: 243px;
	height: 40px;
	border-radius: 4px;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	color: #0275b6;
}

/*---------------------------------Tab----------------------------*/

.untHld-tabs-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 500px;
    height: 300px;
    background: #f1f1f1;
    margin: 100px auto 0;
    word-break: break-all;
    border: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .untHld-tabs-bar {
    display: flex;
    border: 2px solid #035FA4;
    border-radius: 40px;
    background: #ffff;
    padding-right:20px;
    height: 48px;
    font-weight: bold;
  }
  .untHld-tabs {
    margin-top: 4px;
    height: 16px;
    padding: 10px;
    text-align: center;
    width: 50%;
    background: #ffffff;
    color: #9D9EA4;
    cursor: pointer; 
    box-sizing: content-box;
    position: relative;
    outline: none;
    left: 0px;
    border-radius:40px;
    transition-duration:0.2s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .untHld-active-tabs  {
      display:inline-block;
      margin-top: 4px;
      height: 16px;
      left: 5px;
      right: 5px;
      top:0px;
      z-index:1;
   
      border-radius:40px;
      transition-duration:0.2s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      color: #ffff;
      background: #035FA4;

      background: -moz-linear-gradient(45deg, #035FA4 0%, #035FA4 100%);
      background: -webkit-linear-gradient(45deg, #035FA4 0%,#035FA4 100%);
      background: linear-gradient(45deg, #035FA4 0%,#035FA4 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#035FA4', endColorstr='#035FA4',GradientType=1 );
  }
  
  .untHld-active-tabs::before {
    display: block;
    position: absolute;
    top: -5px;
    width: calc(100% + 2px);
    height: 5px;
    background: #035FA4;
  }
  .untHld-button {
    border: none;
  }
  .content-tabs {
    flex-grow : 1;
  }
  .content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .content h2 {
    padding: 0px 0 5px 0px;
  }
  .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .content p {
    width: 100%;
    height: 100%;
  }
  .active-content {
    display: block;
  }

  /*---------------------------------Switch Lock----------------------------*/
  .untHld-lock-bar {
    display: flex;
    border: 2px solid #C00000;
    border-radius: 40px;
    background: #ffff;
    padding-right:4px;
    height: 37px;
    font-weight: bold;
  }
  .untHld-lock {
    margin-top: 4px;
    margin-left: 5px;
    height: 24px;
    text-align: center;
    width: 50%;
    background: #ffffff;
    color: #9D9EA4;
    cursor: pointer; 
    box-sizing: content-box;
    position: relative;
    outline: none;
    left: 0px;
    border-radius:40px;
    transition-duration:0.2s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    cursor: not-allowed;
  }

  
  .untHld-active-lock  {
      display:inline-block;
      margin-top: 3px;
      height: 27px;
      margin-left: 5px;
      left: 5px;
      right: 5px;
      top:0px;
      z-index:1;
   
      border-radius:40px;
      transition-duration:0.2s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      color: #ffff;
      background: #C00000;

      background: -moz-linear-gradient(45deg, #C00000 0%, #C00000 100%);
      background: -webkit-linear-gradient(45deg, #C00000 0%,#C00000 100%);
      background: linear-gradient(45deg, #C00000 0%,#C00000 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#C00000', endColorstr='#C00000',GradientType=1 );
  }

  .untHld-active-lock::before {
    display: block;
    position: absolute;
    top: -5px;
    width: calc(100% + 2px);
    height: 5px;
    background: #035FA4;
  }
  /*---------------------------------Switch Unlock----------------------------*/
  .untHld-unlock-bar {
    display: flex;
    border: 2px solid #00A29B;
    border-radius: 40px;
    background: #ffff;
    padding-right:4px;
    height: 37px;
    font-weight: bold;
  }
  .untHld-unlock {
    margin-top: 4px;
    margin-left: 5px;
    height: 24px;
    text-align: center;
    width: 50%;
    background: #ffffff;
    color: #9D9EA4;
    cursor: pointer; 
    box-sizing: content-box;
    position: relative;
    outline: none;
    left: 0px;
    border-radius:40px;
    transition-duration:0.2s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  
  .untHld-active-unlock  {
      display:inline-block;
      margin-top: 3px;
      height: 27px;
      margin-left: 4px;
      left: 5px;
      right: 5px;
      top:0px;
      z-index:1;
      border-radius:40px;
      transition-duration:0.2s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      color: #ffff;
      background: #00A29B;
      background: -moz-linear-gradient(45deg, #00A29B 0%, #00A29B 100%);
      background: -webkit-linear-gradient(45deg, #00A29B 0%,#00A29B 100%);
      background: linear-gradient(45deg, #00A29B 0%,#00A29B 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00A29B', endColorstr='#00A29B',GradientType=1 );
  }

  .untHld-table-floating-header {
    position: sticky;
    right: 0px;
    width: 85px;
  }
  .untHld-table-floating-cell {
    position: sticky;
    position: -webkit-sticky;
    right: -22px;
    border-bottom: solid 1px;
    border-bottom-color: rgba(0, 0, 0, 0.1);
    width: 85px;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
  
      &:hover {
        opacity: 0.5;
      }
  
      &:active {
        opacity: 1;
      }
    }
  
    p {
      margin-bottom: 0px;
      font-family: Lato;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.18px;
      color: #0275b6;
      text-decoration: underline;
    }
  }


.usrAct-search-header {
	display: flex;
    min-height: 71px;
	margin-left: 20px;
	margin-bottom: 8px;
    flex-direction: row;
    justify-content: flex-start;
}

.usrAct-search-container {
    display: flex;
    flex-direction: column;
}

.usrAct-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 331px;
    height: 40px;
    padding-left: 16px;
    border: solid 1px #cfcfcf;
    background-color: #ffffff;
    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }
}
.usrAct-search-disable{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 331px;
    height: 40px;
    padding-left: 16px;
    border: solid 1px #cfcfcf;
    background-color: #d7d7d7;
    pointer-events: none;
    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }
}

.usrAct-search-bar {
    display: flex;
    flex-grow: 1;
    border: none;
    background-color: transparent;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #aaaaaa;
    &:focus {
        outline: none;
    }
}

.usrAct-list-progress {
    position: absolute;
    // header
    top: 60px;
    bottom: 0;
    // side bar
    left: 200px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #7575756e;
    z-index: 100;
    div svg { color: #0275b6; }
}

.usrAct-list-progress-text {
    margin-top: 16px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    text-shadow: 1px 1px #00000065;
}

.usrAct-search-header {
	display: flex;
    min-height: 71px;
	margin-left: 20px;
	margin-bottom: 8px;
    flex-direction: row;
    justify-content: flex-start;
}

.usrAct-search-container {
    display: flex;
    flex-direction: column;
}

.usrAct-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 23px;
    width: 331px;
    height: 40px;
    padding-left: 16px;
    border: solid 1px #cfcfcf;
    background-color: #ffffff;
    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }
}

.usrAct-search-bar {
    display: flex;
    flex-grow: 1;
    border: none;
    background-color: transparent;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #aaaaaa;
    &:focus {
        outline: none;
    }
}

.usrAct-list-begin-search {
    &:hover {
        cursor: pointer;
    }
    &:active {
        opacity: 0.5;
    } 
}

.usrAct-list-cancel-search {
    &:hover {
        cursor: pointer;
    }
    &:active {
        opacity: 0.5;
    }
}

.usrAct-list-container {
    min-width: 600px;
    min-height: 600px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-left: 20px;
    margin-right: 70px;
    margin-bottom: 22px;
    overflow-x: auto;
	padding: 20px;
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    border-left: solid 1px rgba(0, 0, 0, 0.05);
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
	&::-webkit-scrollbar-track {
        background: #9797977c;
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
        height: 8px;
        background: #9797977c;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background:  #0275b6;
        border-radius: 4px;
        height: 8px;
    }
}

.usrAct-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.usrAct-head-container {
    background-color: #ffffff;
    border-radius: 2px;
    border-bottom: solid 1px;
    border-bottom-color: rgba(0, 0, 0, 0.1);
}

.usrAct-head-row {
    background-color: #ffffff;
}

.usrAct-header {
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #000000;
    height: 62px;
    padding-left: 20px;
}


.usrAct-table-floating-header {
    position: sticky;
    right: 0px;
    width: 132px;
    background-color: #ffffff;
}


.usrAct-header-with-sort {
	display: flex;
	flex-direction: row;
	background-color: transparent;
	border: none;
	text-align: left;
	justify-content: left;
	align-items: left;
	padding-left: 0px;
	font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #000000;
    height: 62px;
	img {
		min-width: 16px;
		max-width: 16px;
		min-height: 16px;
		max-height: 16px;
	}
}

// fund no column
.usrAct-header:nth-child(1) {
    width: 110px;
}

// fund code column
.usrAct-header:nth-child(2) {
    width: 281px;
}

// asset class column
.usrAct-header:nth-child(3) {
    width: 139px;
}

// risk level column
.usrAct-header:nth-child(4) {
    width: 116px;
}

// nav thb column
.usrAct-header:nth-child(5) {
    width: 112px;
}

// nav date column
.usrAct-header:nth-child(6) {
    width: 110px;
}

// fund status column
.usrAct-header:nth-child(7) {
    width: 130px;
}

// action column
.usrAct-header:nth-child(8) {
    width: 132px;
}

.usrAct-table-body {

}

.usrAct-table-empty-row {
    background-color: #ffffff;
    min-height: 660px;
    height: 660px;
}

.usrAct-table-no-data {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 635px;
    margin-left: 21px;
    margin-right: 20px;
    margin-bottom: 50px;
    background-color: #ffffff;
}


.usrAct-table-no-data-header {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.usrAct-table-no-data-body {
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.usrAct-table-row {
    border-bottom: solid 0.5px #dddddd;
}

.usrAct-table-row:nth-child(odd) {
    background-color: #fbfbfb;
}

.usrAct-table-row:nth-child(even) {
    background-color: #ffffff;
}

.usrAct-cell {
    height: 66px;
	padding-left: 20px;
	word-wrap: break-word;
}

.usrAct-status-active {
	width: 66px;
  height: 17px;
  border-radius: 8.5px;
  background-color: #13ce95;
  p {
	  text-align: center;
	  font-family: Lato;
	  font-size: 14px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.29;
	  letter-spacing: 0.2px;
	  color: #ffffff;
  }
}

.usrAct-status-inactive {
	width: 66px;
  height: 17px;
  border-radius: 8.5px;
  background-color: #d56060;
  p {
	  text-align: center;
	  font-family: Lato;
	  font-size: 14px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.29;
	  letter-spacing: 0.2px;
	  color: #ffffff;
  }
}

.usrAct-action-buttons {
	display: flex;
	flex-direction: row;
}

.usrAct-action-view {
	border: none;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: underline;
	font-family: Lato;
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: 0.18px;
	color: #0275b6;
	padding-left: 0px;
	border-right: solid 1px #d6d6d6;
	img {
		width: 16px;
		height: 16px;
	}
}

.usrAct-action-edit {
	border: none;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: underline;
	font-family: Lato;
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: 0.18px;
	color: #0275b6;
	img {
		width: 16px;
		height: 16px;
	}
}

.usrAct-buttons-container {
	margin-top: 23px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.usrAct-upload-doc-button {
	width: 243px;
	height: 40px;
	border-radius: 4px;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	color: #0275b6;
	margin-right: 14px;
	img {
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}
}

.usrAct-create-fund-button {
	width: 243px;
	height: 40px;
	border-radius: 4px;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	color: #0275b6;
}

/*---------------------------------Tab----------------------------*/

.usrAct-tabs-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 500px;
    height: 300px;
    background: #f1f1f1;
    margin: 100px auto 0;
    word-break: break-all;
    border: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .usrAct-tabs-bar {
    display: flex;
    border: 2px solid #035FA4;
    border-radius: 40px;
    background: #ffff;
    padding-right:20px;
    height: 48px;
    font-weight: bold;
  }
  .usrAct-tabs {
    margin-top: 4px;
    height: 16px;
    padding: 10px;
    text-align: center;
    width: 50%;
    background: #ffffff;
    color: #9D9EA4;
    cursor: pointer; 
    box-sizing: content-box;
    position: relative;
    outline: none;
    left: 0px;
    border-radius:40px;
    transition-duration:0.2s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .usrAct-active-tabs  {
      display:inline-block;
      margin-top: 4px;
      height: 16px;
      left: 5px;
      right: 5px;
      top:0px;
      z-index:1;
   
      border-radius:40px;
      transition-duration:0.2s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      color: #ffff;
      background: #035FA4;

      background: -moz-linear-gradient(45deg, #035FA4 0%, #035FA4 100%);
      background: -webkit-linear-gradient(45deg, #035FA4 0%,#035FA4 100%);
      background: linear-gradient(45deg, #035FA4 0%,#035FA4 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#035FA4', endColorstr='#035FA4',GradientType=1 );
  }
  
  .usrAct-active-tabs::before {
    display: block;
    position: absolute;
    top: -5px;
    width: calc(100% + 2px);
    height: 5px;
    background: #035FA4;
  }
  .usrAct-button {
    border: none;
  }
  .content-tabs {
    flex-grow : 1;
  }
  .content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .content h2 {
    padding: 0px 0 5px 0px;
  }
  .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .content p {
    width: 100%;
    height: 100%;
  }
  .active-content {
    display: block;
  }

  /*---------------------------------Switch Lock----------------------------*/
  .usrAct-lock-bar {
    display: flex;
    border: 2px solid #C00000;
    border-radius: 40px;
    background: #ffff;
    padding-right:4px;
    height: 37px;
    font-weight: bold;
  }
  .usrAct-lock {
    margin-top: 4px;
    margin-left: 5px;
    height: 24px;
    text-align: center;
    width: 50%;
    background: #ffffff;
    color: #9D9EA4;
    cursor: pointer; 
    box-sizing: content-box;
    position: relative;
    outline: none;
    left: 0px;
    border-radius:40px;
    transition-duration:0.2s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    cursor: not-allowed;
  }

  
  .usrAct-active-lock  {
      display:inline-block;
      margin-top: 3px;
      height: 27px;
      margin-left: 5px;
      left: 5px;
      right: 5px;
      top:0px;
      z-index:1;
   
      border-radius:40px;
      transition-duration:0.2s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      color: #ffff;
      background: #C00000;

      background: -moz-linear-gradient(45deg, #C00000 0%, #C00000 100%);
      background: -webkit-linear-gradient(45deg, #C00000 0%,#C00000 100%);
      background: linear-gradient(45deg, #C00000 0%,#C00000 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#C00000', endColorstr='#C00000',GradientType=1 );
  }

  .usrAct-active-lock::before {
    display: block;
    position: absolute;
    top: -5px;
    width: calc(100% + 2px);
    height: 5px;
    background: #035FA4;
  }
  /*---------------------------------Switch Unlock----------------------------*/
  .usrAct-unlock-bar {
    display: flex;
    border: 2px solid #00A29B;
    border-radius: 40px;
    background: #ffff;
    padding-right:4px;
    height: 37px;
    font-weight: bold;
  }
  .usrAct-unlock {
    margin-top: 4px;
    margin-left: 5px;
    height: 24px;
    text-align: center;
    width: 50%;
    background: #ffffff;
    color: #9D9EA4;
    cursor: pointer; 
    box-sizing: content-box;
    position: relative;
    outline: none;
    left: 0px;
    border-radius:40px;
    transition-duration:0.2s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  
  .usrAct-active-unlock  {
      display:inline-block;
      margin-top: 3px;
      height: 27px;

      margin-left: 4px;
      left: 5px;
      right: 5px;
      top:0px;
      z-index:1;
   
      border-radius:40px;
      transition-duration:0.2s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      color: #ffff;
      background: #00A29B;

      background: -moz-linear-gradient(45deg, #00A29B 0%, #00A29B 100%);
      background: -webkit-linear-gradient(45deg, #00A29B 0%,#00A29B 100%);
      background: linear-gradient(45deg, #00A29B 0%,#00A29B 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00A29B', endColorstr='#00A29B',GradientType=1 );
  }
 