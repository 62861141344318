@import '../../main';
.wrapper {
  display: flex;
  position: relative;
  min-height: 100vh;
  min-width: 100%;
  overflow-x: hidden;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  flex-direction: row;
  background-color: $white;
  .imageBackgroundWrap {
    background-image: url("../../assets/images/bgImage/rectangle.svg");
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .logoWrap {
      max-width: 199px;
      max-height: 105px;
      img {
        width: 100%;
      }
    }
  }
    .formWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      min-width: 500px;
      position: relative;

      .copyright {
        position: absolute;
        bottom: 29px;

        font-family: "Lato", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 0.93;
        letter-spacing: 0.3px;
        text-align: center;
        color: $greyColor;
      }

      .formContainer {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        max-width: 400px;

        input {
          font-family: 'Lato', sans-serif;
          font-size: 16px;
          line-height: 1.25;
          letter-spacing: 0.2px;
          min-height: 60px;
          color: $greyColor;
          text-transform: none;
        }

        .formName {
          margin-bottom: 70px;

          h2 {
            font-size: 30px;
            line-height: 1.2;
            letter-spacing: 0.3px;
            text-align: center;
            color: $black;
            text-transform: uppercase;
          }
        }
      }
      .link-wrap {
        margin-top: 30px;
      }


    }
  .textStyle {
    width: 57px;
    height: 23px;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formForgotPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Enter-your-email-add {
  margin-top: 26px;
  margin-bottom: 48px;
  min-width: 417px;
  min-height: 46px;
  font-family: Lato-Semibold, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.18px;
  text-align: center;
  color: #4a4a4a;
}

.submit-button {
  margin-top: 51px;
  margin-bottom: 24px;
}

.submit-button-text {
	font-family: 'Lato', sans-serif;
	font-size: 18px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	text-align: center;
	color: #ffffff;
  text-transform: uppercase;
  div svg {
    color: #ffffff;
  }
}

.Back-to-login {
  font-family: $LatoReg, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  line-height: 1.44;
  letter-spacing: 0.18px;
  text-align: center;
  color: #0275b6;
  text-decoration: underline;
}

.forgot-password-email-input {
  min-width: 376px;
  min-height: 60px;
  border-radius: 3px;
  border: solid 1px #d7d7d7;
}

.forgot-password-email-input-error {
  min-width: 376px;
  min-height: 60px;
  border-radius: 3px;
  border: solid 1px #e6233e;
}

.error-message {
  margin-top: 14px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.23px;
  text-align: center;
  color: #e6233e;
}