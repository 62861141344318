@import "../../main.scss";

.modal-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0;
  &.modal-upload {
    width: 465px;
    max-width: none!important;
    margin: auto;
  }
};
.modal-content {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 30px;
  width: 465px;
  min-height: 250px;
  position: absolute;
  .text-modal {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &.m-p {
      padding: 0 50px;
    }


    h4 {
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.28;
      letter-spacing: 0.2px;
      text-align: center;
      color: $black;
      &.pb {
        margin-bottom: 28px;
      }
    }
    p {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: 0.2px;
      text-align: center;
      color: $black;
    }

    .text-modal-body-text {
      font-family: Lato;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.2px;
      text-align: center;
      color: #000000;
    }

  }
  .button-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      margin: 0 5px;
      border-radius: 3px;
    }
  }
  .close-button-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    h4 {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.1px;
      margin: 0;
      text-align: center;
      color: $black;

    }
    button {
      position: absolute;
      top: 15px;
      background: transparent;

      .svg {
        fill: $greyColor
      }
    }

  }


}

.btn-alert {
  background-color: white;
  color: #0275b6;
  font-size: 18px;
  line-height: 1.28;
  border: 2px solid #0275b6;
  letter-spacing: 0.2px;
  text-align: center;
}