.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    min-height: 40px;
    height: 40px;
}

.pagination-first {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 60px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    &:active {
        opacity: 0.5;
    }
}

.pagination-last {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 10px;
    min-width: 60px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    &:active {
        opacity: 0.5;
    }
}

.pagination-previous {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 10px;
    min-width: 40px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    &:active {
        opacity: 0.5;
    }  
}

.pagination-previous-disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 10px;
    min-width: 40px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    opacity: 0.5;  
}

.pagination-next {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 10px;
    min-width: 40px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    &:active {
        opacity: 0.5;
    }  
}

.pagination-next-disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 10px;
    min-width: 40px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    &:active {
        opacity: 0.5;
    }  
    opacity: 0.5;
}

.pagination-button {
    margin-left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    &:active {
        opacity: 0.5;
    }
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    text-align: center;
    color: #0275b6;
}

.pagination-button-selected {
    margin-left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background-color: #0275b6;
    &:active {
        opacity: 0.5;
    }
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    text-align: center;
    color: #ffffff;
}
// ---Next and Prevoius----
.UnitHld-table-pagination-previous-disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    min-width: 40px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    opacity: 0.5;  
}


.UnitHld-table-pagination-previous{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    min-width: 40px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    &:active {
        opacity: 0.5;
    }  
}

.UnitHld-table-pagination-next-disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    min-width: 40px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    &:active {
        opacity: 0.5;
    }  
    opacity: 0.5;
}
.UnitHld-table-pagination-next {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    min-width: 40px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: #ffffff;
    &:active {
        opacity: 0.5;
    }  
}