@import "../../main";

.usrAct-tab-progress {
  width: 100%;
  padding-top: 150px;
  position: absolute;
  // header
  top: 60px;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #7575756e;
  z-index: 100;
  div svg { color: #0275b6; }
}

.usrAct-tab-progress-text {
  text-align: center;
  margin-top: 16px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  text-shadow: 1px 1px #00000065;
}
.usrAct-feed-form-container {
  margin-left: 21px;
  margin-right: 20px;
  padding-top: 23px;
}
.usrAct-buttons-container {
	margin-top: 23px;
  margin-right: 0px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.usrAct-detail-cancel {
	width: 131px;
	height: 40px;
	border-radius: 3px;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	text-align: center;
	color: #0275b6;
	margin-right: 14px;
	background-color: transparent;
}

.usrAct-detail-delete {
	width: 190px;
	height: 40px;
	border-radius: 3px;
	border: solid 1px #FF0000;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	text-align: center;
	color: #FF0000;
	margin-right: 14px;
	background-color: transparent;
  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  &:active {
      opacity: 1;
      text-decoration: none;
  }
}

.usrAct-detail-delete-disable {
	width: 190px;
	height: 40px;
	border-radius: 3px;
	border: solid 1px #9D9EA4;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	text-align: center;
	color: #9D9EA4;
	margin-right: 14px;
	background-color: transparent;

}

.usrAct-feed-form-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.content-feed-form-delete-icon {
  padding-right: 5px;
}

.content-feed-form-delete-button {
  border: none;
  background-color: transparent;
  width: 142px;
  height: 40px;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  display: flex;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
  }
}

.content-feed-form-cancel-button {
  width: 142px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: transparent;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  margin-right: 17px;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
  }
}

.usrAct-viewlog-button {
  width: 180px;
  height: 40px;
  border-radius: 3px;
  background-color: #035FA4;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
  }
}

.content-feed-form-publish-button-disabled {
  width: 131px;
  height: 40px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  opacity: 0.2;
}

.content-feed-form-save-publish-button {
  width: 178px;
  height: 40px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
  }
}

.content-feed-form-save-publish-button-disabled {
  width: 178px;
  height: 40px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  opacity: 0.2;
}

.usrAct-feed-form-inputs-container {
	  background-color: #fff;
    margin-top: 25px;
    padding: 40px 51px 40px 61px;
    min-width: 860px;
    display: flex;
    flex-direction: column;
}

.usrAct-feed-form-input-spacer {
  height: 24px;
}

.content-feed-form-cover-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.feed-error-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  height: 20px;
}

.feed-error-text {
  margin-bottom: 0px;
  min-width: 598px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.23px;
  color: #e6233e;
}

.feed-error-spacer {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  width: 242px;
}

.content-feed-form-image-preview-container {
  display: flex;
  flex-direction: row;
}

.content-feed-form-image-holder {
  min-width: 598px;
}

.content-feed-form-image-preview {
  width: 50px;
  height: 50px;
}

.content-feed-form-cover-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 598px;
  min-height: 40px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
}

.content-feed-form-cover-input-container-error {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 598px;
  min-height: 40px;
  border: solid 1px #e6233e;
  background-color: #ffffff;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
}

.content-feed-textinput-error {
  border: solid 1px #e6233e;
}

.content-feed-form-cover-input-hidden {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.content-feed-form-cover-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
  width: 242px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.content-feed-form-cover-label-inner {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 0px;
  p {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-bottom: 0px;

    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #000000;
  }
}

.content-feed-form-cover-input-placeholder {
  display: flex;
  flex-grow: 1;
  margin-bottom: 0px;
}

.content-feed-form-cover-icon {
  padding-right: 8px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.content-feed-form-switch-button-container {
  display: flex;
  flex-direction: row;
}

.selected-switch-button {
  width: 90px;
  height: 30px;
  border-radius: 15px;
  border-color: #0275b6;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

.unselected-switch-button {
  width: 90px;
  height: 30px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

.contentfeed-multiline {
  display: flex;
  flex-direction: column;
}

.contentfeed-multilinelabel {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.contentfeed-multilineinput {
  margin-top: 18px;
  min-width: 800px;
  min-height: 320px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
  resize: none;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #000000;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 54px;
  &::placeholder {
    color: #737373;
  }
  &:focus {
    outline: none;
  }
}

/*---------------------------------Tab----------------------------*/
.tabs-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
 overflow-y: clip;
  background: #ffffff;
  margin: 20px auto 0;
  word-break: break-all;
}

.tabs-bar {
  display: flex;
  border: 2px solid #035FA4;
  border-radius: 40px;
  background: #ffff;
  padding-right:10px;
  height: 48px;
  font-weight: bold;
}
.tabs {
  margin-top: 4px;
  height: 16px;
  padding: 10px;
  text-align: center;
  width: 50%;
  background: #ffffff;
  color: #9D9EA4;
  cursor: pointer; 
  box-sizing: content-box;
  position: relative;
  outline: none;
  left: 0px;
  border-radius:40px;
  transition-duration:0.2s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.active-tabs  {
    display:inline-block;
    margin-top: 4px;
    height: 16px;
    left: 5px;
    right: 5px;
    top:0px;
    z-index:1;
 
    border-radius:40px;
    transition-duration:0.2s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #ffff;
    background: #035FA4;

    background: -moz-linear-gradient(45deg, #035FA4 0%, #035FA4 100%);
    background: -webkit-linear-gradient(45deg, #035FA4 0%,#035FA4 100%);
    background: linear-gradient(45deg, #035FA4 0%,#035FA4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#035FA4', endColorstr='#035FA4',GradientType=1 );
}

.active-tabs::before {
  display: block;
  position: absolute;
  top: -5px;
  width: calc(100% + 2px);
  height: 5px;
  background: #035FA4;
}
button {
  border: none;
}
.content-tabs {
  flex-grow : 1;
}
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}