@import "../../main.scss";
.header-spacer {
	height: 69px;
}

.input-spacer {
	height: 30px;
}

.password-instruction-text {
	min-width: 376px;
  	min-height: 68px;
	background-color: #f4f4f4;
	font-family: Lato;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: 0.2px;
	color: #9b9ba3;  
	padding-top: 9px;
	padding-bottom: 8px;
	padding-left: 12px;
	padding-right: 7px;
	margin-top: 17px;
	margin-bottom: 50px;
}

.button-resend-code {
	background-color: transparent;
	outline: none;
	border: none;
	text-decoration: underline;
	font-family: Lato;
	font-size: 16px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.44;
	letter-spacing: 0.18px;
	color: #0275b6; 
}

.reset-password-input {
	min-width: 376px;
	min-height: 60px;
	border-radius: 3px;
	border: solid 1px #d7d7d7;
}

.login-button {
	width: 250px;
	min-height: 60px;
}

.success-container {
	display: flex;
	flex-direction: column;
}

.password-reset-success {
	margin-top: 26px;
	margin-bottom: 48px;
	min-width: 417px;
	min-height: 46px;
	font-family: Lato-Semibold, sans-serif;
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.44;
	letter-spacing: 0.18px;
	text-align: center;
	color: #4a4a4a;
}

.Back-to-login {
	font-family: $LatoReg, sans-serif;
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 600;
	line-height: 1.44;
	letter-spacing: 0.18px;
	text-align: center;
	color: #0275b6;
	text-decoration: underline;
}

.login-button-text {
	div svg {
		color: #ffffff;
	  }
}

.error-message {
	margin-top: 14px;
	font-family: Lato;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.07;
	letter-spacing: 0.23px;
	text-align: center;
	color: #e6233e;
}

.input-error {
	min-width: 376px;
	min-height: 60px;
	border-radius: 3px;
	border: solid 1px #e6233e;
  }