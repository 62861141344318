.input-wrapper {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff; /* Default background */
  width: 100%;
  box-sizing: border-box;

  &:focus-within {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }

  &.disabled {
    background-color: #f7f7f7; /* Background for disabled */
    border: 1px solid #e0e0e0; /* Light border */
    cursor: not-allowed;
  }
}

.input-box {
  flex-grow: 1;
  border: none;
  background-color: transparent; /* Always transparent */
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #424242;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: #9e9e9e;
  }
}
