@import "../../main.scss";
.sideBarMenuWrap {
  flex: 0 0 230px;
  background-color: $backgroundBlue;
  display: flex;
  flex-direction: column;

  .logoWrap {
    width: 100%;
    height: 60px;
    display: flex;
    img {
      width: 69px;
      margin: auto;
    }
  }

  .sideBar {
    ul {
      padding: 0;
      a {
        list-style: none;
        display: flex;
        flex-direction: column;
        color: $white;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 1.29;
        text-transform: uppercase;
        position: relative;
        text-decoration: none; 
        .menu-item-title {
          height: 50px;
          display: flex;
          align-items: center;
          padding-left: 16px;
          font-family: 'Lato', sans-serif;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: normal;
          transition: all 0.3s;
          &:hover {
            background-color: $blueDarkLink;
            text-decoration: underline;  // Add text underline on hover
          }
        }

        .nested-list {
          display: block;
          position: relative;
          width: 100%;
          transition: all 0.3s;
          a {
            text-decoration: none; 
            &:hover {
              text-decoration: underline; 
            }
          }
          a.active {
            background-color: $blueDarkLink;
          }
        }

        &.active {
          background-color: $blueDarkLink;
          border-left: 4px solid $white;
          img:not(:first-child) {
            transform: rotate(180deg);
          }
          .menu-item-title {
            padding-left: 12px;
          }
          .nested-list {
            a.active {
              background-color: $blueDarkLink;
            }
          }
        }

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          &.arrow {
            width: 15px;
            height: 15px;
            margin-left: 15px;
          }
        }
      }

      a:nth-child(3):hover .nested-list {
        display: block;
      }

      a.transaction_link {
        .nested-list {
          display: block;
          width: 100%;
          li {
            display: flex;
            justify-content: left;
            padding-left: 47px;
            background-color: $lightLink;
            height: 50px;
            font-family: 'Raleway', sans-serif;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: normal;
            
            &.active {
              background-color: $darkActiveLink;
              border-left: 4px solid $white;
              span {
                margin-left: -4px;
              }
            }
            &:hover {
              text-decoration: underline;
              background-color: $blueDarkLink ; 
            }

            a {
              text-decoration: none; 
              &:hover {
                text-decoration: underline; 
              }
            }
          }
        }

        &.active {
          background-color: $blueDarkLink;
        }
      }
    }
  }
}

.nested-list-span {
  display: flex;
  align-items: center;
  padding: 15px;
}

.sideBar-copyright-text {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
  padding-bottom: 9px;
  opacity: 0.5;
  font-family: Lato;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
