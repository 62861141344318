
@import '../../main';

.headerLabel {
  font-family: $LatoBold, sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.77;
  letter-spacing: 0.33px;
  text-align: center;
  color: #000000;
}

.labelInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
}

.labelInputText {
  width: 242px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
} 

.infoImage {
  width: 15px;
  height: 15px;
}

.input-popover {
	width: 200px;
	height: 50px;
	background-color: #000000;
	pointer-events: 'none';
}

.popover-div {
	position: absolute;
	background-color: #3cff00;
	width: 200px;
	height: 200px;
}

.textInside {
	padding-right: 100px;
	padding-left: 5px;
  	padding-top: 3px;
	padding-bottom: 3px;
	border: solid 1px #d7d7d7;
	font-family: $LatoReg, sans-serif;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.2px;
	color: #000000;
}

.textInput {
  padding-left: 15px;
  min-width: 598px;
  min-height: 40px;
  border: solid 1px #d7d7d7;
  font-family: $LatoReg, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #000000;
  &::placeholder {
    color: #737373;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
	border: solid 1px #cfcfcf;
	background-color: #f7f7f7;
	color: #424242;
  }
  
}
.textInput-invalid {
	 padding-left: 15px;
 	 min-width: 598px;
  	min-height: 40px;
	border: solid 1px red;
  font-family: $LatoReg, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #000000;
  &:focus {
    outline: none;
  }
}
.textInput-warning {
	padding-left: 15px;
 min-width: 598px;
 min-height: 40px;
   border: solid 1px yellow;
 font-family: $LatoReg, sans-serif;
 font-size: 16px;
 font-weight: normal;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.25;
 letter-spacing: 0.2px;
 color: #000000;
 &:focus {
   outline: none;
 }
}

.errorBorder {
	border: solid 1px #e6233e !important;
}

.inputValue {
	font-family: $LatoReg, sans-serif;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.2px;
	color: #000000;
}

.placeholderValue {
	font-family: Lato;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.2px;
	color: #aaaaaa;
}

.labelMultilineInput {
  display: flex;
  flex-direction: row;
}

.labelMultilineInputText {
  width: 242px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.textMultilineInput {
  min-width: 598px;
  min-height: 90px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
  resize: none;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #000000;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 54px;
  &::placeholder {
    color: #737373;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
	border: solid 1px #cfcfcf;
	background-color: #f7f7f7;
	color: #aaaaaa;
  }
}

.hide {
  opacity: 0;
}

.textDropdownInput {
  min-width: 598px;
  min-height: 40px;
  height: 40px;
  line-height: 40px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.2px;
  appearance: none;
  background: url("../../assets/images/ic-arrow-darkpurple.svg") no-repeat right;
  background-position:
    calc(100% - 10px),
    calc(100% - 7px),
    100% 0;
  padding-left: 15px;
  padding-left: 10px;
  &:focus {
    outline: none;
  }
  &:disabled {
	border: solid 1px #cfcfcf;
	background: url("../../assets/images/ic-arrow-darkpurple.svg") no-repeat right #f7f7f7;
	background-position:
    calc(100% - 10px),
    calc(100% - 7px),
    100% 0;
	color: #aaaaaa;
	option {
		color: #aaaaaa;
	}
  }
}

.optionText {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #000000;
}

.optionDisabledText {
	font-family: Lato;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.2px;
	color: #aaaaaa;
  }

  .react-datepicker-wrapper{
	  width: 100%;
  }

.datepickerInputController {
	width: 100%;
	border: none;
	background: transparent;
	outline: none;
}

.datepickerInput {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 598px;
	min-height: 40px;
	height: 40px;
	border: solid 1px #cfcfcf;
	background-color: #ffffff;
	font-family: Lato;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: 0.2px;
	color: #000000;
	appearance: none;
	padding-left: 15px;
	padding-left: 10px;
	&:focus {
	  outline: none;
	}
	img {
		 width: 20px;
		 height: 20px;
		 margin-right: 8px;
	}
	p {
		margin-bottom: 0px;
	}
}

.datepickerInputDisabled {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 598px;
	min-height: 40px;
	height: 40px;
	border: solid 1px #cfcfcf;
	background-color: #f7f7f7;
	font-family: Lato;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: 0.2px;
	appearance: none;
	padding-left: 15px;
	padding-left: 10px;
	&:focus {
	  outline: none;
	}
	img {
		 width: 20px;
		 height: 20px;
		 margin-right: 8px;
	}
	p {
		margin-bottom: 0px;
		color: #aaaaaa;
	}
}

.datepickerInputError {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 598px;
	min-height: 40px;
	height: 40px;
	border: solid 1px #e6233e;
	font-family: Lato;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: 0.2px;
	color: #000000;
	appearance: none;
	padding-left: 15px;
	padding-left: 10px;
	&:focus {
	  outline: none;
	}
	img {
		 width: 20px;
		 height: 20px;
		 margin-right: 8px;
	}
	p {
		margin-bottom: 0px;
	}
}

.radioInput {
	display: flex;
	flex-direction: row !important;

	label{
	span {
		font-family: Lato;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: 0.2px;

		span {
			svg {
				fill: #0275b6
			}
		}
	
	}
	}
	
}

.radioDisabledInput {
	display: flex;
	flex-direction: row !important;

	label{
	span {
		font-family: Lato;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: 0.2px;

		span {
			svg {
				fill: #dddddd
			}
		}
	
	}
	}
}

  .fileinput-hidden {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  }

  .fileinput-label {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0px;

	padding-left: 15px;
	min-width: 598px;
	min-height: 40px;
	border: solid 1px #d7d7d7;
	font-family: $LatoReg, sans-serif;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.2px;
	color: #000000;

	p {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		margin-bottom: 0px;
	
		font-family: Lato;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: 0.2px;
		color: #000000;
		padding-left: 10px;
	}
	&:disabled {
		border: solid 1px #cfcfcf;
  		background-color: #f7f7f7;
	}
  }

  .fileinput-label-disabled {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0px;

	padding-left: 15px;
	min-width: 598px;
	min-height: 40px;
	border: solid 1px #cfcfcf;
  	background-color: #f7f7f7;
	font-family: $LatoReg, sans-serif;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.2px;
	color: #000000;

	p {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		margin-bottom: 0px;
	
		font-family: Lato;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: 0.2px;
		color: #aaaaaa;
		padding-left: 10px;
	}
  }

  .fileinput-label-error {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0px;

	padding-left: 15px;
	min-width: 598px;
	min-height: 40px;
	border: solid 1px #e6233e !important;
	font-family: $LatoReg, sans-serif;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.2px;
	color: #000000;

	p {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		margin-bottom: 0px;
	
		font-family: Lato;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: 0.2px;
		color: #000000;
		padding-left: 10px;
	}
  }

  .fileinput-remove {
	background-color: transparent;
	border: none;
	padding-right: 11px;
	img {
		width: 14px;
		height: 14px;
	}
  }

.checkbox-input {
	display:none;
}

.checkbox-input-disabled {
	display:none;
}

.checkbox-input + label {
	display: flex;
	flex-direction: row;
	margin-bottom: 0px;

	width: 18px;
	height: 18px;
	opacity: 0.5;
	border-radius: 2px;
	border: solid 1.5px #9b9b9b;
	margin-right: 100px;
	p { 
		padding-left: 26px;
		min-width: 100px;
		min-height: 18px;
		font-family: Lato;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: 0.2px;
		color: #000000;
		margin-bottom: 0px;
	}
}

.checkbox-input:checked + label {
	display: flex;
	flex-direction: row;
	margin-bottom: 0px;
	background: url("../../assets/images/checkbox-selected-ic.svg") no-repeat;

	width: 18px;
	height: 18px;
	border: none;
	opacity: 1;
	margin-right: 100px;
	p { 
		padding-left: 26px;
		min-width: 100px;
		font-family: Lato;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: 0.2px;
		color: #000000;
		margin-bottom: 0px;
	}
}

.checkbox-input-disabled + label {
	display: flex;
	flex-direction: row;
	margin-bottom: 0px;

	width: 18px;
	height: 18px;
	opacity: 0.5;
	border-radius: 2px;
	border: solid 1.5px #9b9b9b;
	margin-right: 100px;
	p { 
		padding-left: 26px;
		min-width: 100px;
		min-height: 18px;
		font-family: Lato;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: 0.2px;
		color: #000000;
		margin-bottom: 0px;
	}
}

.checkbox-input-disabled:checked + label {
	display: flex;
	flex-direction: row;
	margin-bottom: 0px;
	background: url("../../assets/images/checkbox-selected-disabled-ic.svg") no-repeat;

	width: 18px;
	height: 18px;
	border: none;
	opacity: 1;
	margin-right: 100px;
	p { 
		padding-left: 26px;
		min-width: 100px;
		font-family: Lato;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: 0.2px;
		color: #000000;
		margin-bottom: 0px;
	}
}


.checkbox-label {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #000000;
  margin-right: 43px;
  margin-bottom: 0px;
}

.selectInputContainer {
	min-width: 598px;
	max-width: 598px;
	min-height: 40px;

	font-family: Lato;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.2px;
	color: #aaaaaa;
	

	.__multi-value__label {
		font-family: Lato;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.2px;
		color: #000000;
	}

	&:disabled {
		border: solid 1px #cfcfcf;
		  background-color: #f7f7f7;
	}
}

.select-input-error__control {
	border: solid 1px #e6233e !important;
	border-radius: 0px !important;
}

.select-input__control {
	border: solid 1px #cfcfcf !important;
	border-radius: 0px !important;
}

.select-input__indicators {
	display: none !important;
}

.select-input-error__indicators {
	display: none !important;
}