@import '../../main.scss';

button.btnProj {
  padding: 0;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  &:hover {
    opacity: 0.8;
  }
}
button.btnProj.bluer {
  opacity: 0.5;
  pointer-events: none;
}

button.btnProj.link {
  border-bottom: 1px solid black;
  border-left: none;
  border-right: none;
  border-top: none;
  background: transparent;
  border-radius: 0;
}

button.btnProj.non-border {
  border: none;
  background: transparent;
  outline-style: none;
  color: $blueLink;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;

  i {
    margin-right: 10px;
  }

  @media all and (max-width: 1200px){
    margin-left: 10px;
  }
}

button.btnProj.primary-link {
  border-bottom: 1px solid $blueLink;
  border-left: none;
  border-right: none;
  border-top: none;
  color: $blueLink;
  background: transparent;
  border-radius: 0;
  line-height: 1;

}

.btnProj.primary {
  color: $white;
  border-bottom: 2px solid $blueLink;
  border-right: none;
  background-color: $blueLink;
  border-color: $blueLink;
  text-transform: uppercase;
  font-size: 18px;
  font-family:'Lato', sans-serif;
}

.btnProj.primary-edit {
  background-color: $blueLink;
  color: $white;
  font-size: 18px;
  line-height: 1.28;
  border: 2px solid $blueLink;
  letter-spacing: 0.2px;
  text-align: center;
}
.btnProj.primary-border{
  border: 2px solid $blueLink;
  color: $blueLink;
  border-radius: 5px;
  background-color: transparent;
  font-size: 18px;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
}
.btnProj.primary-border-transparent{
  border: 2px solid $blueLink;
  color: $blueLink;
  border-radius: 3px;
  background-color: transparent;
  font-size: 18px;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 15px;

  .button-icon-wrap {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding:0 5px;

    img {
      margin-right: 10px;
    }
  }
}

.btnProj.status_active {
  width: 66px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  height: 19px;
  border-radius: 8.5px;
  background-color: $greenLink;
  color: $white;
  border: none;
  font-size: 14px;
}
.btnProj.status_inactive {
  width: 66px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  height: 19px;
  border-radius: 8.5px;
  background-color: $inactiveButton;
  color: $white;
  border: none;
  font-size: 14px;


}
.btnProj.close-button {
  border: none;
  width: 20px;
  position: absolute;
  right: 20px;
}

.btnProj.start_upload {
  background-color: $blueLink;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnProj.success_upload {
  background-color: $greenButton;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnProj.login {
  background-color: $blueButtonColor;
  color: $white;
  border: none;
  text-transform: uppercase;
  font-size: 18px;
  font-family:'Lato', sans-serif;
}


.switch-container {
  margin: 20px 0 20px;
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  span {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: $inactiveValue;
    margin-left: 8px;
  }
}

input[type="checkbox"].switch  {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"].switch + div {
  vertical-align: middle;
  border-radius: 100px;
  background-color: #d9d9d9;
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type="checkbox"].switch + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child( 1 ) {
  margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child( 2 ) {
  margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
  width: 124px;
  background-position: 0 0;
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #d9d9d9;
}

input[type="checkbox"].switch + div {
  width: 124px;
  height: 27px;
}


input[type="checkbox"].switch:checked + div {
  background-color: #d9d9d9;
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
}

input[type="checkbox"].switch + div > div {
  float: left;
  width: 101px; height: 27px;
  border-radius: inherit;
  background-color: $blueLink;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: $white;
  -webkit-transition-timing-function: cubic-bezier(1,0,0,1);
  transition-timing-function: cubic-bezier(1,0,0,1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 0;
  margin-left: 0;

  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.2px;
  text-align: center;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #6c6c6c;

}

input[type="checkbox"].bigswitch.switch + div > div {
  width: 101px;
  height: 27px;
  margin-top: 1px;

}


input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(23px, 0, 0);
  transform: translate3d(23px, 0, 0);
}

