@import "../../main";
.wrapper {
  display: flex;
  position: relative;
  min-height: 100vh;
  min-width: 100%;
  overflow-x: hidden;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  flex-direction: row;
  background-color: $white;
  .imageBackgroundWrapDefaultPage {
    background-image: url("../../assets/images/bgImage/rectangle.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
    height: 100vh;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.txtVersion
{
  color:#ffffff;
  position: absolute; 
  bottom: 0;
  right: 0;
  margin-bottom: 5px;
  margin-right: 5px;
}