@import "../../main.scss";

.input-wrap.text,
.input-wrap.password {
  margin-bottom: 40px;
  .input-item {
    font-family: 'Lato', sans-serif;
    position: relative;
    input {
      padding: 20px 15px;
      border-radius: 3px;
      border: 1px solid $greyBorder;
      outline: none;
      width: 100%;
      max-width: 376px;
      text-transform: none;

    }

    .error-message {
      position: absolute;
      left: 30%;
      bottom: -22px;
      font-size: 14px;
      color: $redColor
    }

  }
  .input-item.error {
    input {
      border: 1px solid $redColor;
    }
  }
}

.input-wrap.password {
  margin-bottom: 45px;
}

.form-wrap.error-border {
  .input-item {
    input {
      border: 1px solid $redColor;
    }
  }
}