.funddetails-container {
	background-color: white;
	border-radius: 2px;
  	box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	margin-top: 24px;
	margin-left: 21px;
	margin-right: 20px;
	margin-bottom: 24px;
	padding-left: 33px;
	padding-right: 33px;
	padding-top: 40px;
	padding-bottom: 50px;
}

.funddetails-container-hide {
	display: none;
}

.funddetails-spacer {
	min-height: 24px;
}

.funddetails-status-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	h3 {
		font-family: Lato;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: 0.2px;
		color: #000000;
		margin-top: 0px;
		margin-bottom: 0px;
		min-width: 268px;
	}
	p {
		font-family: Lato;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: 0.2px;
		color: #aaaaaa;
		margin-bottom: 0px;
		margin-left: 8px;
	}
}

.funddetails-status-active-container {
	border-radius: 14.5px;
	box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
	border: solid 1px #dadada;
	background-color: #d9d9d9;
	padding-right: 29px;
}

.funddetails-status-inactive-container {
	border-radius: 14.5px;
	box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
	border: solid 1px #dadada;
	background-color: #d9d9d9;
	padding-left: 29px;
}

.funddetails-status-active {
	width: 101px;
	height: 29px;
	border-radius: 14.5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #0275b6;
	background-color: #0275b6;
	font-family: Lato;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.2px;
	text-align: center;
	color: #ffffff;
	&:disabled {
		opacity: 0.5;
		border: none;
	}
}

.funddetails-status-inactive {
	width: 108px;
	height: 29px;
	border-radius: 14.5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #6c6c6c;
	background-color: #6c6c6c;
	font-family: Lato;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.2px;
	text-align: center;
	color: #ffffff;
	&:disabled {
		opacity: 0.5;
		border: none;
	}
}

.funddetails-inactivated {
	font-family: Lato;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: 0.2px;
	color: #aaaaaa;
}

.funddetails-inputs {
	padding-left: 28px;
}

.fundcode-modified {
	min-width: 558px;
	border-right: none !important;
}

.fundcode-modified-has-cancel {
	min-width: 518px;
	border-right: none !important;
}

.search-button {
	min-width: 40px;
	min-height: 40px;
	background-color: transparent;
	border-left: none;
	border-top: solid 1px #cfcfcf;
	border-right: solid 1px #cfcfcf;
	border-bottom: solid 1px #cfcfcf;
}

.search-button-error {
	min-width: 40px;
	min-height: 40px;
	background-color: transparent;
	border-left: none;
	border-top: solid 1px #e6233e;
	border-right: solid 1px #e6233e;
	border-bottom: solid 1px #e6233e;
}

.search-button-cancel {
	min-width: 40px;
	min-height: 40px;
	background-color: transparent;
	border-left: none;
	border-top: solid 1px #cfcfcf;
	border-right: none;
	border-bottom: solid 1px #cfcfcf;
}

.search-button-cancel-error {
	min-width: 40px;
	min-height: 40px;
	background-color: transparent;
	border-left: none;
	border-top: solid 1px #e6233e;
	border-right: none;
	border-bottom: solid 1px #e6233e;
}

.fundcode-error-message {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 36px;
	max-height: 36px;
	padding-top: 24px;
	div {
		width: 242px;
	}
	img {
		width: 36px;
		height: 36px;
		object-fit: contain;
	}
	p {
		font-family: Lato;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.07;
		letter-spacing: 0.23px;
		text-align: center;
		color: #e6233e;
		margin-bottom: 0px;
	}
}