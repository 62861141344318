.loaderStyles {
  position: absolute;
  left: 20px;
}

.photoLoaderStyles {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 56px;
  left: 56px;
  transform: translate(-50%, -50%);
  background: transparent;
  padding-top: 15%;
  z-index: 1;
  text-align: center;
}