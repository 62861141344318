.tabforms-tab-container {
	background-color: white;
	padding-left: 43px;
	padding-bottom: 32px;
}

.tabforms-tabbutton-selected {
	margin-right: 16px;
	height: 34px;
	border-radius: 17px;
	background-color: #0275b6;
	border: solid 1px #0275b6;
	padding-left: 18px;
	padding-right: 18px;
	font-family: Lato;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: 0.2px;
	color: #ffffff;
}

.tabforms-tabbutton {
	margin-right: 16px;
	height: 34px;
	border-radius: 17px;
	background-color: transparent;
	border: none;
	text-decoration: underline;
	padding-left: 18px;
	padding-right: 18px;
	font-family: Lato;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.2px;
	text-align: center;
	color: #0275b6;
}

.tabforms-spacer {
	min-height: 24px;
	background-color: #ffffff;
}

.tabforms-hide {
	display: none;
}

.tabforms-investmentpolicy {
	background-color: #ffffff;
	padding-left: 61px;
	padding-bottom: 39px;
	padding-right: 33px;
}

.tabforms-fundnature {
	background-color: #ffffff;
	padding-left: 61px;
	padding-bottom: 39px;
	padding-right: 33px;
}

.tabforms-fees {
	background-color: #ffffff;
	padding-left: 32px;
	padding-bottom: 39px;
	padding-right: 33px;
	h3 {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 32px;
		font-family: Lato;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: 0.2px;
		color: #000000;
	}
}

.unitholder-section {
	padding-left: 29px;
	padding-bottom: 40px;
}

.fund-section {
	padding-left: 29px;
}

.mini-title {
	padding-bottom: 24px;
	font-family: Lato;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: 0.2px;
	color: #000000;
}