.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 26px;
  min-height: 60px;
  max-height: 60px;
  background-color: #ffffff;
}

.logoOutWrap {
  button {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #4a4a4a;
    border-bottom: none !important;
  }
}

.logout-icon {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}
