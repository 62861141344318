.upload-doc-progress {
  position: absolute;
  // header
  top: 0;
  bottom: 0;
  // side bar
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #7575756e;
  z-index: 100;
  div svg {
    color: #0275b6;
  }
}

.upload-doc-progress-text {
  margin-top: 16px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  text-shadow: 1px 1px #00000065;
}

.upload-doc-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(3, 3, 3, 0.62);
}

.upload-doc-modal {
  position: relative;
  min-width: 465px;
  min-height: 353px;
  border-radius: 3px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #e2e1e1;
  background-color: #ffffff;
}

.upload-doc-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  margin-top: 11px;
  margin-bottom: 23px;
  padding-left: 20px;
  padding-right: 28px;
  width: 100%;
  h1 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: 0.13px;
    text-align: left;
    color: #000000;
    align-self: flex-start;
    flex-grow: 1;
  }
  button {
    background-color: transparent;
    border: none;
    &:hover {
      cursor: pointer;
    }
    &:active {
      opacity: 1;
    }
    &:hover {
      opacity: 0.5;
    }
    img {
      width: 16px;
      height: 16px;
    }
  }
}

.upload-doc-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
  padding-top: 19px;
}

.upload-doc-cancel {
  width: 148px;
  height: 44px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: transparent;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  margin-right: 18px;
  &:active {
    opacity: 1;
  }
  &:hover {
    opacity: 0.5;
  }
}

.upload-doc-submit {
  width: 148px;
  height: 44px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  &:active {
    opacity: 1;
  }
  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.25;
  }
}

.upload-doc-input-container {
  width: 420px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #dddddd;
  margin-left: 21px;
  margin-right: 24px;
  margin-bottom: 24px;
}

.upload-doc-input-hidden {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-doc-label-inner {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 0px;
  max-width: 420px;
  p {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-bottom: 0px;
    max-width: 420px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #000000;
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.upload-doc-tick-icon {
  width: 54px;
  height: 40px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #3eb391;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
}

.upload-doc-upload-icon {
  width: 54px;
  height: 40px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #0275b6;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
}

.upload-doc-error-icon {
  width: 54px;
  height: 40px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #d56060;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
}

.upload-doc-remove-doc {
  background-color: transparent;
  border: none;
  padding-right: 11px;
  img {
    width: 14px;
    height: 14px;
  }
}

.upload-doc-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 353px;
  flex-grow: 1;
  padding-top: 10px;
  p {
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 70px;
  }
}

.upload-doc-success-img {
  display: flex;
  flex-grow: 1;
  padding-bottom: 80px;
}

.upload-doc-ok {
  margin-bottom: 34px;
  width: 148px;
  height: 44px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: transparent;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  &:active {
    opacity: 1;
  }
  &:hover {
    opacity: 0.5;
  }
}

.upload-doc-failed {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.23px;
  text-align: center;
  color: #e6233e;
}
