@import '../../main';

.button-create-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 23px;
    margin-bottom: 25px;
}

.button-create-new-content {
    width: 238px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #0275b6;
    background-color: transparent;
    margin-right: 22px;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #0275b6;
    padding-top: 9px;
    &:hover {
        opacity: 0.5;
        text-decoration: none;
    }
    &:active {
        opacity: 1;
        text-decoration: none;
    }
}

.feed-list-progress {
    margin-left: 30px;
    position: absolute;
    // header
    top: 60px;
    bottom: 0;
    // side bar
    left: 200px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #7575756e;
    z-index: 100;
    div svg { color: #0275b6; }
}

.feed-list-progress-text {
    margin-top: 16px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    text-shadow: 1px 1px #00000065;
}

.feed-list-container {
    min-width: 600px;
    min-height: 600px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 22px;
    overflow-x: auto;
    padding: 20px;
}

.feed-list-category {
    p {
        margin-bottom: 0px;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.2px;
        color: #484848;
    }
}

.feed-list-cell-account-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
        width: 25px;
        height: 24px;
    }
}

.feed-list-cell-account-status-unpublished {
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #e5af09;
}

.feed-list-cell-account-status-published {
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #13ce95;
}

.feed-list-article-button {
    padding-left: 0px;
    text-align: left;
    width: 100%;
    background-color: transparent;
    border: transparent;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 20px;
    white-space: nowrap;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0275b6;
    text-decoration: underline;
}

.feed-list-modified-by {
    word-wrap: break-word;}

.feed-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.feed-head-container {
    background-color: #ffffff;
    border-radius: 2px;
    border-bottom: solid 1px;
    border-bottom-color: rgba(0, 0, 0, 0.1);
}

.feed-head-row {
    background-color: #ffffff;
}

.feed-header {
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #000000;
    height: 62px;
    padding-left: 20px;
}

// Number column
.feed-header:nth-child(1) {
    width: 64px;
}

// Article title column
.feed-header:nth-child(2) {
    width: 305px;
}

// Category column
.feed-header:nth-child(3) {
    width: 154px;
}

// Modified By column
.feed-header:nth-child(4) {
    width: 273px;
}

// Modified Date column
.feed-header:nth-child(5) {
    width: 201px;
}

// Status column
.feed-header:nth-child(6) {
    width: 151px;
}



.feed-table-empty-row {
    background-color: #ffffff;
    min-height: 660px;
    height: 660px;
}

.feed-table-no-data {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 635px;
    margin-left: 21px;
    margin-right: 20px;
    margin-bottom: 50px;
    background-color: #ffffff;
}

.feed-table-no-data-header {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.feed-table-no-data-body {
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.feed-table-row {
    border-bottom: solid 0.5px #dddddd;
}

.feed-table-row:nth-child(odd) {
    background-color: #fbfbfb;
}

.feed-table-row:nth-child(even) {
    background-color: #ffffff;
}

.feed-cell {
    height: 66px;
    padding-left: 20px;
}

.list-body {
    display: flex;
    flex-grow: 1;
    min-height: 300px;
    margin-top: 25px;
    margin-left: 21px;
    margin-right: 20px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.article-title-button {
    border: transparent;
    background-color: transparent;
    text-decoration: underline;
}

.no-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #484848;
    margin-bottom: 0px;
}

.article-title-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0275b6;
}

.category-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #484848;
    margin-bottom: 0px;
}

.modified-by-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #484848;
    margin-bottom: 0px;
}

.modified-date-column {
    padding-left: 8px;
}

.modified-date-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #484848;
    margin-bottom: 0px;
}

.status-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status-image {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.status-text-draft {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #e5af09;
    margin-bottom: 0px;
}

.status-text-published {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #13ce95;
    margin-bottom: 0px;
}

.conFd-table-cell-account-status-check {
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #13ce95;
}
.conFd-table-cell-account-status-nocheck {
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #C4C6C5;
}

.conFd-synce-button {
    width: 131px;
    height: 40px;
    margin-right: 22px;
    border-radius: 3px;
    background-color: #0275b6;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #ffffff;
    &:hover {
      opacity: 0.5;
    }
    &:active {
      opacity: 1;
    }
  }