// color

$white:#ffffff;
$bagGrey:#f1f1f1;

$redButtonColor:#b10202;
$blueButtonColor:#0275b6;

$redColor:#e6233e;

$backgroundRed:#ae0a12;
$backgroundBlue:#0275b6;

$purpleLink:#00305B;
$redLink:#ef252e;
$blueLink:#0275b6;
$blueDarkLink:#004d78;
$darkActiveLink:#00324e;
$lightLink:#1E6CAF;

$greenLink: #13ce95;
$greenButton: #3eb391;
$yellow: #e5af09;
$inactiveButton: #d56060;
$greyBorder: #d7d7d7;
$greyColor: #737373;
$lightGreyColor: #DDDDDD;
$black: #000000;
$darkColor: #4a4a4a;
$greyColorText: #484848;
$placeholderColor: #cfcfcf;
$bottomLabel: #858585;
$searchBorder: #f1f1f1;
$inactiveValue: #aaaaaa;
$inactivePlaceholder: #f7f7f7;
$borderInputGrey: #C9C8C9;
$searchPlaceholder: #979797;


  // font constants
$LatoReg: 'Lato';
$LatoMed: 'Lato-Med';
$LatoBold: 'Lato-Bold';
$UbuntuReg: 'Ubunti-Reg';


button {
  outline: none;
}
* {
  -webkit-text-size-adjust: 100%;
}
