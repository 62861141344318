@import "../../main.scss";

.rsp-list-progress {
    position: absolute;
    // header
    top: 60px;
    bottom: 0;
    // side bar
    left: 200px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #7575756e;
    z-index: 100;

    div svg {
        color: #0275b6;
    }
}

.rsp-list-progress-text {
    margin-top: 16px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    text-shadow: 1px 1px #00000065;
}

.rsp-search-header {
    display: flex;
    min-height: 95px;
    margin-right: 20px;
    flex-direction: row;
    justify-content: flex-end;
}

.rsp-search-container {
    display: flex;
    flex-direction: column;
}

.rsp-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 23px;
    width: 331px;
    height: 40px;
    padding-left: 16px;
    border: solid 1px #cfcfcf;
    background-color: #ffffff;

    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }
}

.rsp-search-bar {
    display: flex;
    flex-grow: 1;
    border: none;
    background-color: transparent;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #aaaaaa;

    &:focus {
        outline: none;
    }
}

.rsp-list-begin-search {
    &:hover {
        cursor: pointer;
    }

    &:active {
        opacity: 0.5;
    }
}

.rsp-list-cancel-search {
    &:hover {
        cursor: pointer;
    }

    &:active {
        opacity: 0.5;
    }
}

.rsp-list-container {
    min-width: 600px;
    min-height: 600px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 22px;
    overflow-x: auto;

    &::-webkit-scrollbar-track {
        background: #9797977c;
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
        height: 8px;
        background: #9797977c;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #0275b6;
        border-radius: 4px;
        height: 8px;
    }
}


.rsp-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.rsp-head-container {
    background-color: #ffffff;
    border-radius: 2px;
    border-bottom: solid 1px;
    border-bottom-color: rgba(0, 0, 0, 0.1);
}

.rsp-head-row {
    background-color: #ffffff;
}

.rsp-header {
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #000000;
    height: 62px;
    padding-left: 20px;
}

.rsp-header-with-sort {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border: none;
    text-align: left;
    justify-content: left;
    align-items: center;
    padding-left: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #000000;
    height: 62px;

    img {
        min-width: 16px;
        max-width: 16px;
        min-height: 16px;
        max-height: 16px;
    }
}

// Number column
.rsp-header:nth-child(1) {
    width: 64px;
}

// unitholder id column
.rsp-header:nth-child(2) {
    width: 140px;
}

// full name column
.rsp-header:nth-child(3) {
    width: 124px;
}

// fund code column
.rsp-header:nth-child(4) {
    width: 148px;
}

// trans type column
.rsp-header:nth-child(5) {
    width: 100px;
}

// entry date column
.rsp-header:nth-child(6) {
    width: 150px;
}

// effective date column
.rsp-header:nth-child(7) {
    width: 150px;
}

// amount thb column
.rsp-header:nth-child(8) {
    width: 100px;
}

// recurring payment due column
.rsp-header:nth-child(9) {
    width: 150px;
}

// frequency column
.rsp-header:nth-child(10) {
    width: 156px;
}

// status column
.rsp-header:nth-child(11) {
    width: 140px;
}

.rsp-table-body {}

.rsp-table-empty-row {
    background-color: #ffffff;
    min-height: 660px;
    height: 660px;
}

.rsp-table-no-data {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 635px;
    margin-left: 21px;
    margin-right: 20px;
    margin-bottom: 50px;
    background-color: #ffffff;
}


.rsp-table-no-data-header {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.rsp-table-no-data-body {
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.rsp-table-row {
    border-bottom: solid 0.5px #dddddd;
}

.rsp-table-row:nth-child(odd) {
    background-color: #fbfbfb;
}

.rsp-table-row:nth-child(even) {
    background-color: #ffffff;
}

.rsp-cell {
    height: 66px;
    padding-left: 20px;

}

// full name cell we show ellipsis for names that are too long
.rsp-cell:nth-child(3) {
    text-overflow: ellipsis;
    overflow: hidden;
}

.rsp-date-range-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 21px;
    height: 40px;
    width: 420px;
    margin-bottom: 20px;
}

.date-range-filter-date {
    margin-right: 12px;
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: #737373;
}

.rsp-table-cell-account-status {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        width: 25px;
        height: 24px;
    }
}

.rsp-table-cell-account-status-pending {
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #e5af09;
}

.rsp-table-cell-account-status-approved {
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #13ce95;
}

.rsp-table-cell-account-status-rejected {
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #d56060;
}

.date-range-separator {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #0275b6;
}

.date-range-filter {
    margin-left: 16px;
    width: 108px;
    height: 40px;
    border-radius: 3px;
    border-color: #0275b6;
    background-color: #0275b6;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.25;
    }

    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #ffffff;
}

.filter-date-instructions {
    margin-top: 100px;
    display: flex;
    flex-grow: 1;
    min-height: 180px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Filter-date-to-view {
    width: 354px;
    height: 23px;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}

.Select-the-date-rang {
    width: 320px;
    height: 34px;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
}