@import "../../main";

.feed-form-progress {
  position: absolute;
  // header
  top: 60px;
  bottom: 0;
  // side bar
  left: 200px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #7575756e;
  z-index: 100;
  div svg {
    color: #0275b6;
  }
}

.feed-form-progress-text {
  margin-top: 16px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  text-shadow: 1px 1px #00000065;
}

.ndid-feed-form-container {
  margin-left: 21px;
  margin-right: 20px;
  padding-top: 23px;
}
.ndid-buttons-container {
	margin-top: 23px;
  margin-right: 0px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.ndid-detail-cancel {
	width: 131px;
	height: 40px;
	border-radius: 3px;
	border: solid 1px #0275b6;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: 0.2px;
	text-align: center;
	color: #0275b6;
	margin-right: 14px;
	background-color: transparent;
}

.ndid-feed-form-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.content-feed-form-delete-icon {
  padding-right: 5px;
}

.content-feed-form-delete-button {
  border: none;
  background-color: transparent;
  width: 142px;
  height: 40px;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  display: flex;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
  }
}

.content-feed-form-cancel-button {
  width: 142px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #0275b6;
  background-color: transparent;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  margin-right: 17px;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
  }
}

.content-feed-form-publish-button {
  width: 131px;
  height: 40px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
  }
}

.content-feed-form-publish-button-disabled {
  width: 131px;
  height: 40px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  opacity: 0.2;
}

.content-feed-form-save-publish-button {
  width: 178px;
  height: 40px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
  }
}

.content-feed-form-save-publish-button-disabled {
  width: 178px;
  height: 40px;
  border-radius: 3px;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
  opacity: 0.2;
}

.ndid-feed-form-inputs-container {
	  background-color: #fff;
    margin-top: 25px;
    padding: 40px 51px 40px 61px;
    min-width: 860px;
    display: flex;
    flex-direction: column;
}

.ndid-feed-form-input-spacer {
  height: 24px;
}

.content-feed-form-cover-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.feed-error-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  height: 20px;
}

.feed-error-text {
  margin-bottom: 0px;
  min-width: 598px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.23px;
  color: #e6233e;
}

.feed-error-spacer {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  width: 242px;
}

.content-feed-form-image-preview-container {
  display: flex;
  flex-direction: row;
}

.content-feed-form-image-holder {
  min-width: 598px;
}

.content-feed-form-image-preview {
  width: 50px;
  height: 50px;
}

.content-feed-form-cover-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 598px;
  min-height: 40px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
}

.content-feed-form-cover-input-container-error {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 598px;
  min-height: 40px;
  border: solid 1px #e6233e;
  background-color: #ffffff;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
}

.content-feed-textinput-error {
  border: solid 1px #e6233e;
}

.content-feed-form-cover-input-hidden {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.content-feed-form-cover-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
  width: 242px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.content-feed-form-cover-label-inner {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 0px;
  p {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-bottom: 0px;

    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #000000;
  }
}

.content-feed-form-cover-input-placeholder {
  display: flex;
  flex-grow: 1;
  margin-bottom: 0px;
}

.content-feed-form-cover-icon {
  padding-right: 8px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.content-feed-form-switch-button-container {
  display: flex;
  flex-direction: row;
}

.selected-switch-button {
  width: 90px;
  height: 30px;
  border-radius: 15px;
  border-color: #0275b6;
  background-color: #0275b6;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

.unselected-switch-button {
  width: 90px;
  height: 30px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0275b6;
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

.contentfeed-multiline {
  display: flex;
  flex-direction: column;
}

.contentfeed-multilinelabel {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #000000;
}

.contentfeed-multilineinput {
  margin-top: 18px;
  min-width: 800px;
  min-height: 320px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
  resize: none;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: #000000;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 54px;
  &::placeholder {
    color: #737373;
  }
  &:focus {
    outline: none;
  }
}
