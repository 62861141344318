.performance-container {
	background-color: white;
	border-radius: 2px;
	box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	margin-top: 24px;
	margin-left: 21px;
	margin-right: 20px;
	margin-bottom: 24px;
	padding-top: 40px;
	padding-left: 61px;
	padding-bottom: 40px;
	padding-right: 61px;
}

.performance-container-hide {
	display: none;
}

.performance-spacer {
	min-height: 24px;
}