.date-range-input {
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	width: 130px;
	height: 40px;
	border-radius: 4px;
	border: solid 1px #0275b6;
	p {
		margin-bottom: 0px;
		margin-right: 5px;
		font-family: Lato;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: 0.2px;
		color: #0275b6;
	}
	img {
		width: 18px;
		height: 18px;
		object-fit: contain;
	}
}
.date-range-input-disable {
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	width: 130px;
	height: 40px;
	border-radius: 4px;
	border: solid 1px #0275b6;
	text-decoration:line-through;
	p {
		margin-bottom: 0px;
		margin-right: 5px;
		font-family: Lato;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: 0.2px;
		color: #0275b6;
	}
	img {
		width: 18px;
		height: 18px;
		object-fit: contain;
	}
}